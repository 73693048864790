export enum AuditLogType {
    CREATED = "created",
    UPDATED = "updated",
    RELATEDFIELDCOPY = "relatedFieldCopy",
    CALCULATEDFIELD = "calculatedField",
    FIELDUPDATE = "fieldUpdate",
    AUTONUMBER = "autoNumber",
    AGGREGATEDFIELD = "aggregatedField",
    ACCOUNTSPECIFICFINANCIALFIELDS = "accountSpecificFinancialFields",
    AUDITLOGBEFORE2023 = "auditLogBefore2023"
  }