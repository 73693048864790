
import { Component, Prop, Vue } from "vue-property-decorator";
import FieldChange from "../../Models/FieldChange";

@Component({
    components: {}
})
export default class AuditLogNumberField extends Vue {
    @Prop({required: true})change: FieldChange;
    @Prop()onlyNewValue: boolean;
    loading = true

    get oldValue(){
        if(this.change.oldValue || this.change.oldValue === 0 || this.change.oldValue === '0'){
            return this.change.oldValue
        }
        return this.$t("auditLogs.messages.emptyValue") 
    }
    get newValue(){
        if(this.change.newValue || this.change.newValue === 0 || this.change.newValue === '0'){
            return this.change.newValue
        }
        return this.$t("auditLogs.messages.emptyValue")
    }

    mounted() {
        this.loading = false;
    }
}
