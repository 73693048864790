

import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import DynamicField from "@/components/Forms/DynamicField.vue";
import BaseSectionRelatedRecords from '@/components/Base/BaseSectionRelatedRecords.vue';
import BaseSectionAttachment from "@/components/Base/BaseSectionAttachment.vue";
import BaseSectionMedia from "@/components/Base/BaseSectionMedia.vue";
import BaseSectionSubForm from '@/components/Base/BaseSectionSubForm.vue';
import BaseSectionProductLines from '@/components/Base/BaseSectionProductLines.vue';
import BaseSectionEmails from '@/components/Base/BaseSectionEmails.vue';
import Utils from "@/utils/Utils";
import store from "@/store";

  @Component({
    components: {
      BaseSectionEmails,
      BaseSectionSubForm, BaseSectionProductLines,
      DynamicField, BaseSectionRelatedRecords, BaseSectionAttachment, BaseSectionMedia},
  })
  export default class DynamicSection extends Vue {
    @Prop(Array) formSections;
    @Prop(Object) section;
    @Prop(Object) lineSection;
    @Prop(Object) formData;
    @Prop(Object) showTemplateEmailForm;
    @Prop(Array) tenantEmailsTemplates;
    @Prop(Array) userEmailsTemplates;
    @Prop(Boolean) subFormMode;

    highLight = false;

    get isShowProductLines() {
      return this.formData.data.ID || this.$store.state.PORTAL
    }
    get isDebugMode() {
      return store.state.debugMode
    }
    get flabel() {
      return this.section ? this.section.label : '';
    }
    get fname() {
      return this.section ? this.section.name : '';
    }
    get isFirstColumn() {
        return this.section.fields.first.length;
    }
    get isSecondColumn() {
        return this.section.fields.second.length;
    }
    get isFullColumn() {
        return this.section.fields.full.length;
    }
    get firstFields() {
        return this.section.fields.first;
    }
    get secondFields() {
        return this.section.fields.second;
    }
    get fullFields() {
        return this.section.fields.full;
    }
    get sectionType() {
      return this.section.type;
    }
    get relatedModule() {
      return this.section.relatedModule;
    }
    get relatedByField() {
      return this.section.relatedByField;
    }
    get subFormField() {
      return this.section.subFormField ?? this.section.name;
    }
    get subRecordField() {
      return this.section.subRecordField ?? this.section.name;
    }
    get sectionLabel() {
      if(store.state && store.state.fieldTranslations[`${this.formData.module}.sections.${this.section.name}`]) {
        return store.state.fieldTranslations[`${this.formData.module}.sections.${this.section.name}`]
      }
      return this.section.label;
    }
    get sectionColumns() {
      return this.section.columns;
    }
    get customButtons() {
      return this.section.customButtons;
    }
    get lineType() {
      // 'salesLines', 'invoiceLines', 'generalLines', 'purchaseLines'
      return this.section.lineType;
    }
    get sectionEmailField() {
      return this.section.emailField;
    }
    get isHidden() {
      const isHidden =  this.section.hidden || !this.isModuleShow;
      if(isHidden) return true;
      const fieldWiseControl = this.fieldWiseControl('HIDDEN')
      if(fieldWiseControl) return true;
      return false
    }
    get isModuleShow() {
      
      if (this.section.type === 'relatedRecords') {
        return this.formData.guard.checkModule(this.section.relatedModule);
      }
      if (this.section.type === 'productLines') {
        return this.formData.guard.checkModule(this.section.lineType);
      }
      return true;
    }
    get sectionThreadIDField() {
      return this.section.threadIDField;
    }
    fieldWiseControl(prop, section?) {
      const sectionName = section ? section.name : this.section.name
      if(this.formData.dynamicModule.sections[sectionName] && this.formData.dynamicModule.sections[sectionName].fieldWiseControl) {
        const fieldWiseControl = this.formData.dynamicModule.sections[sectionName].fieldWiseControl
        const field = fieldWiseControl.field
        const value = this.formData.data[field] 
        if(!Utils.isEmpty(value) && fieldWiseControl && fieldWiseControl.values) {
          if(fieldWiseControl.values[value] === prop) {
            return true
          }
        }
      }
      return false
    }
    saveTheForm(needCallbackAlert = true, addSettings = {}) {
      this.$emit('saveTheForm', needCallbackAlert, addSettings);
    }
    addFieldToValidationList(data) {
      this.$emit('addFieldToValidationList', data);
    }
    focusToNextField(name){
      this.$emit('focusToNextField', name)
    }
    onBaseFieldChanged(field) {
      this.$emit('baseFieldChanged', field)
    }
    setUnsavedData() {
      this.$emit('setUnsavedData')
    }

    get isFirstSection() {
      const visibleSections = []
      if(this.formSections) {
        for(const section of this.formSections) {
          if(!section.hidden) {
            const checkFieldWiseControlHidden = this.fieldWiseControl("HIDDEN", section)
            if(!checkFieldWiseControlHidden) {
              visibleSections.push(section)
            }
          }
        }
        if(this.section && this.section.order == visibleSections[0].order) {
          return true
        }
      }
      return false
    }

    // TODO add right CSS class to show sections was changed from the backend
    @Watch('formData.changedFields', {deep: true})
    handleHighLight() {
      if (this.formData.changedFields.includes(this.fname)) {
        this.highLight = true;
        setTimeout(() => this.highLight = false, 5000);
        const index = this.formData.changedFields.indexOf(this.fname);
        if (index > -1) {
          this.formData.changedFields.splice(index, 1);
        }
      }
    }
  }
