
import StatusValidation from "./StatusValidation.vue"
import { mapState } from "vuex";
import store from "@/store"
export default {
  name: "StatusStepper",
  components: {StatusValidation},
  props: {
    formData: null,
    locked: null,
    isModalForm: null,
  },
  data() {
    return {
      dialog: false,
      dialogFields: [],
      dialogSectionErrors: [],
      dialogSectionsValid: true,
      modalStep: null,
      currentStatus: null,
      validationList: {},
      resetValidationList: {},
      requiredFieldValidated: false,
      checklist: [],
      colLgSmall: 'col-lg-7',
      colLgBig: 'col-lg-9'
    }
  },
  computed: {
    ...mapState(['dynamicModules']),
    requiredDynamicFields() {
      const dynamicFields = this.formData.dynamicFields;
      const mandatoryFields = [];

      for (const field in dynamicFields) {
        if (dynamicFields[field]?.mandatory === true) {
          const fieldValue = this.formData.data[dynamicFields[field].name];
          const data = this.dataToString(fieldValue);
          if(!data) {
            mandatoryFields.push(dynamicFields[field]);
          }
        }
      }
      return mandatoryFields;
    },
    dynamicFields() {
      return this.formData.dynamicFields || {};
    },
    statusStepper() {
      const result = [];
      const statusField = this.dynamicFields.status;
      const isStepperNeeded = statusField && statusField.type === 'status' || statusField.name === 'status' && statusField.metaData;
      if (isStepperNeeded) {
        Object.keys(statusField.metaData).forEach(e => result.push(statusField.metaData[e]))
      }
      return result.sort((a, b) => a.order - b.order);
    },
    showStatusStepper() {
      return this.statusStepper.length > 0
    },
    availableStatuses() {
      let result = [];
      const dialogFields = (this.dynamicFields).status.metaData;
      for (const field in dialogFields) {
        if (dialogFields[field].label === this.formData["data"].status) {
          result.push(dialogFields[field])
          for (const status in dialogFields[field].canTransitionTo) {
            const availableStatus = dialogFields[field].canTransitionTo[status]
            result.push(dialogFields[availableStatus])
          }
        }
      }

      const dialogFieldLabels = []
      for(const status in dialogFields) { 
        dialogFieldLabels.push(dialogFields[status].label)
      }

      if(!dialogFieldLabels.includes(this.formData['data'].status)) {
        const statuses = []
        for(const dialogField in dialogFields) {
          statuses.push(dialogFields[dialogField])
        }
        result = statuses
      }
      return result
    },
    status() {
      return this.formData.data.status
    },
  },
  mounted() {
    if(this.isModalForm) {
      this.colLgSmall = 'col-lg-7'
      this.colLgBig = 'col-lg-10'
    }
  },
  methods: {
    async openDialog(status) {
      this.dialog = true
      this.modalStep = status
    },
    isStatusActive(label) {
      return label === this.formData.data["status"];
    },
    getStatusLabel(label) {
        for(const status of this.availableStatuses) {
          if(status.label === label) {
            const translationKey = this.formData.module  + '.status.' + status.label
            if(store.state && store.state.dropDownMap && store.state.dropDownMap[translationKey]) {
              return store.state.dropDownMap[translationKey];
            } 
            return status.label
          }
        }
    },
    emitFormData(value) {
      this.formData = value
    },
    closeDialog(value) {
      this.dialog = value
    },
    dataToString(data) {
      if(typeof data === 'string' || typeof data === 'boolean' || typeof data === 'number' ) {
        return data;
      }
      if (data === null || data === undefined){
        return '';
      }
      if(typeof data === 'object') {
        return data.name;
      }
    }
  },
  watch: {
    currentStatus() {
      if (this.currentStatus && this.currentStatus !== this.formData.data.status && (!this.modalStep.requiredFields || this.requiredFieldValidated) && !this.requiredDynamicFields) {
        this.formData.data.status = this.currentStatus
      }
    }
  },
  beforeMount() {
    this.requiredFieldValidated = false;
  }
}
