
import { Component, Vue, Prop } from "vue-property-decorator";
import FilePickerDialog from "./Parts/FilePickerDialog.vue";
import store from "../../store"
import db from "../../db"

@Component({
    components: {
        FilePickerDialog,
    }
})


export default class BaseFieldRecordImage extends Vue {
    @Prop({required: true}) formData: any
    @Prop({required: true}) module: any
    showFilePicker = false
    recordImageAvailable = true
    imageURL = ''

    mounted() {
      if(this.formData && this.formData.recordImage) {
        this.recordImageAvailable = true
        this.imageURL = this.formData.recordImage.downloadableURL
      } else {
        this.recordImageAvailable = false
      }
    }

    closeFilePickerDialog() {
      this.showFilePicker = false
    }

    async uploadedFile(image) {
      this.formData.recordImage = image
      this.imageURL = image.downloadableURL
      if(this.imageURL) {
        this.recordImageAvailable = true
      }
      await this.saveImage(image)
    }
    
    async saveImage(image) {
      await db.collection(`tenants/${store.state.tenantID}/modules/${this.module}/records`).doc(this.formData.ID).set({
        recordImage: image
      }, {merge: true})
    }
}
