
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuditLogFieldType } from "../../Models/AuditLogFieldType";
import ParsedAuditLog from "../../Models/ParsedAuditLog";
import AuditLogTextField from "../Fields/AuditLogTextField.vue";

@Component({
    components: {
        AuditLogTextField
    }
})
export default class AuditLogAutoNumberFields extends Vue {
    @Prop({required: true})auditLog: ParsedAuditLog;
    loading = true
    AuditLogFieldType = AuditLogFieldType

    mounted() {
        this.loading = false;
    }
}
