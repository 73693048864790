export enum AuditLogFieldType {
    AUTONUMBER = "autoNumber",
    TEXT = "text",
    LARGETEXT = "largeText",
    DROPDOWN = "dropdown",
    PERCENTAGE = "percentage",
    CURRENCY = "currency",
    CHECKBOX = "checkbox",
    RELATEDFIELD = "relatedField",
    DATE = "date",
    DATETIME = "datetime",
    NUMBER = "number",
    DECIMAL = "decimal"
  }