
import { Component, Prop, Vue } from "vue-property-decorator";
import FieldChange from "../../Models/FieldChange";

@Component({
    components: {}
})
export default class AuditLogTextField extends Vue {
    @Prop({required: true})change: FieldChange;
    @Prop()onlyNewValue: boolean;
    loading = true

    mounted() {
        this.loading = false;
    }
}
