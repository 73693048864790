import store from "@/store";
import Vue from "vue";

export default class CF {
  static async doCustomButtonAction(buttonAction, module, ID) {
    const params = {buttonAction, module, ID};
    await store.dispatch('actions/runAction', params).then((res) => {
      buttonAction.loading = false;
      let message: any = 'done';
      
      if (buttonAction.successMessage) {
        message = {text: buttonAction.successMessage};
      }
      store.dispatch('showAlertMessage', message);
    }).catch(err => {
      buttonAction.loading = false;
      let message: any = 'error';
      console.error(err);
      if (buttonAction.errorMessage) {
        message = {text: buttonAction.errorMessage, color: 'negative'};
      }
      store.dispatch('showAlertMessage', message);
    });
  }

  static customButtons(dynModuleData) {
    const buttons = dynModuleData.customButtons ?? {};
    for (const buttonsKey in buttons) {
      Vue.set(buttons[buttonsKey], 'loading', false);
    }
    
    return buttons;
  }
}
