
import { Component, Prop, Vue } from "vue-property-decorator";
import FieldChange from "../../Models/FieldChange";

@Component({
    components: {}
})
export default class AuditLogCurrencyField extends Vue {
    @Prop({required: true})change: FieldChange;
    @Prop()onlyNewValue: boolean;
    loading = true

    get oldValue(){
        if(this.change.oldValue || this.change.oldValue === 0 || this.change.oldValue === '0'){
            return '€' + (this.change.oldValue / 10000).toFixed(2)
        }
        return this.$t("auditLogs.messages.emptyValue")
    }
    get newValue(){
        if(this.change.newValue || this.change.newValue === 0 || this.change.newValue === '0'){
            return '€' + (this.change.newValue / 10000).toFixed(2)
        }
        return this.$t("auditLogs.messages.emptyValue")
    }

    mounted() {
        this.loading = false;
    }
}
