import AbModel from "@/components/Models/AbModel";
import store from "@/store";
import db from "@/db";

class ViewModel extends AbModel {

  view = '';

  constructor(view = '', module = '') {
    super();
    this.setDynViewName(view);
    this.setDynModuleName(module);
  }

  setDynViewName(view) {
    this.view = view;
  }

  async getViewInfoDoc(view = '', module = '') {
    const lview = view ? view : this.view;
    const lmodule = module ? module : this.module;
    const firePath = "tenants/" + store.state.tenantID + "/views/" + lview + "/modules/";
    const result: any = await db.collection(firePath).doc(lmodule).get();
    const docModule = result.data();
    return docModule;
  }

  async getFieldsInfoDoc(module = '') {
    const docView = await this.getViewInfoDoc();
    if (!docView) {
      return {
        sections: {},
        fields: {}
      };
    }
    const lmodule = module ? module : this.module;
    const firePath = "tenants/" + store.state.tenantID + "/modules/";
    const result: any = await db.collection(firePath).doc(lmodule).get();
    const docModule = result.data();

    // filter sections
    const dynSections = docModule.sections || {};
    let viewSections = {};
    if (Array.isArray(docView.sections)) {
      docView.sections.forEach(s => {
        viewSections[s.name] = s
      });
    } else {
      viewSections = docView.sections;
    }

    // add visibleSections if them not in the section
    if (docView.visibleSections && Array.isArray(docView.visibleSections)) {
      docView.visibleSections.forEach(s => {
        if (!viewSections[s]) {
          viewSections[s] = {}
        }
      });
    }

    const visibleSections = {};
    for (const secName in viewSections) {
      const vSection = viewSections[secName] || {}
      if (dynSections[secName]) {
        const dSection = dynSections[secName]
        visibleSections[secName] = {...dSection, ...vSection};
      }
    }
    docModule.sections = visibleSections;
    

    // filter fields
    
    
    const dynFields = docModule.fields || {};
    const visibleFields = {};
    for (const fn in docView.fields || []) {
      const vField = docView.fields[fn]
      if (dynFields[fn]) {
        const dField = dynFields[fn]
        visibleFields[fn] = {...dField, ...vField};
        
        if (dField.type === 'subForm') {
          for (const dFieldKey in vField.fields || {}) {
            vField.fields[dFieldKey] =  {...dField.fields[dFieldKey] || {}, ...vField.fields[dFieldKey]};
          }
          
        }
      }
    }

    // add subFormField for sub forms sections
    for (const secName in visibleSections) {
      const vSection = visibleSections[secName]
      if (vSection.type === 'subForm') {
        const subFormField = vSection.subFormField;
        const sField = dynFields[subFormField] || {};
        visibleFields[subFormField] = {...visibleFields[subFormField], ...sField};
      }
    }

    docModule.fields = visibleFields;
    
    
    return docModule;
  }

}

export default ViewModel;
