
  import { Vue, Component, Prop, Watch } from "vue-property-decorator";
  import { State, Action } from "vuex-class";
  import functions from '@/functions';
  import store from '@/store';
  import QPdfviewer from "@/components/Settings/Sections/Templates/PDFgenerator/Helpers/QPdfviewer";
  import { EventBus } from "@/event-bus";
  import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
  import ListValues from "@/components/Mixin/ListValues";
  import RenderDocTemplateRequest from "@/dto/api/RenderDocTemplateRequest.dto";
  import PreviewFileDialog from "@/components/Parts/PreviewFileDialog.vue"
import axios from "axios";

  @Component({
    components: { QPdfviewer, PreviewFileDialog }
  })
  export default class PrintPreview extends Vue {
    @Prop(String) module;
    @Prop(Object) formData;
    @Prop(Boolean) hasEmail;
    @Prop(Object) defaultTemplate;
    @Prop(Object) pdfTemplatesData;
    @Prop({ type: Array, default: () => { return [] } }) formTemplates!: Array<any>
    @State("PORTAL") PORTAL;
    @State("tenantID") tenantID;
    @Action("settings/getPDFTemplatesData") getPDFTemplatesData;
    @Action("settings/getModuleSettings") getModuleSettings;

    template: any = null;
    loadedTemplates = [];
    drawer = true;
    src = '';
    base64PDF = null;
    fileName = '';

    mediaPagination = {
      rowsPerPage: 10
    };
    showEditData = false;
    mediaFieldName = '';
    mediaData: Array<any> = [];
    mediaColumns = [
      {
        name: 'stage',
        label: 'Stage',
        field: 'stage',
        align: 'left',
        sortable: true,
      },
      {
        name: 'media',
        label: 'Media',
        field: 'downloadableURL',
        align: 'center',
      },
      {
        name: 'name',
        label: 'Name',
        field: 'fileName',
        align: 'left',
        sortable: true,
      },
      {
        name: 'delete',
        label: 'Delete',
        field: 'delete',
        align: 'left',
        sortable: true,
      }
    ];
    recordMediaToDelete = {};
    showModalDeleteMedia = false;
    designIcons = [];
    showDocDialog = false
    renderedTemplate = null

    get iconsLoaded() {
      return Object.values(this.designIcons).length
    }

    sendButtonEnable() {
      return this.src;
    }

    async getPDFfile() {
      if (!this.src) {
        return false;
      }
      await this.getOutputPDF(this.template, 'base64');
      const file = {
        name: this.fileName + '.pdf',
        type: 'application/pdf',
        base64PDF: this.base64PDF
      }
      return file;
    }

    async onSaveToAttachment() {
      if(this.template && this.template.fileType === 'docx') {
        const docTemplate = await this.generateDocFile(this.renderedTemplate)
        EventBus.$emit('needToSavePDFToAttachment', docTemplate);
        return docTemplate
      }
      const file = await this.getPDFfile();
      if (!file) {
        return false;
      }
      EventBus.$emit('needToSavePDFToAttachment', file);
      return file
    }

    async generateBase64FromDocxUrl(docxUrl: string): Promise<string> {
      try {
        const response = await axios.get(docxUrl, { responseType: 'arraybuffer' });
        const base64Content = Buffer.from(response.data, 'binary').toString('base64');
        return base64Content;
      } catch (error) {
        throw new Error(`Error generating base64 from URL: ${error}`);
      }
    }


    async generateDocFile(template: any) {
      const base64 = await this.generateBase64FromDocxUrl(template.downloadableURL)
      const file = {
        name: template.name,
        base64PDF: base64,
        sourcePath: `${this.module}/records/${this.formData.data.ID}/attachments/`,
        tags: [],
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }
      return file
    }

    async onSendAsEmail() {
      const file = await this.onSaveToAttachment()
      this.$emit("onToolbarPrintPreviewClose", file);
    }

    getStageName(stage) {
      return [
          this.$t('attachments.headers.mediaBefore'),
          this.$t('attachments.headers.mediaDuring'),
          this.$t('attachments.headers.mediaAfter'),
      ][stage - 1];
    }

    async getMediaData() {
      this.mediaData = [];
      for(const df in this.formData.dynamicSections) {
        if (this.formData.dynamicSections[df].type === 'media') {
          this.mediaFieldName = df;
          this.mediaData = this.formData.data[df].slice();
        }
      }
    }

    onCloseForm() {
      if (this.showEditData) {
        // close edit data
        this.showEditData = false;
        if (this.template && this.template.id) {
          this.getOutputPDF(this.template);
        }
      } else {
        this.$emit("onToolbarPrintPreviewClose");
      }
    }

    get show() {
      return Boolean(this.src) && !this.showEditData;
    }

    async chooseTemplate() {
      this.showDocDialog = false
      if(this.template && this.template.fileType === 'pdf') {
        const selectedPDFTemplateID = this.template && this.template.id ? this.template.id : '';
        this.$emit('onChooseTemplate', { selectedPDFTemplateID })
      } else if (this.template && this.template.fileType === 'docx') {
        const formData = await PdfTools.getFormDataForPDF(this.template.module, this.formData.data.ID);
        const dataToRender = await PdfTools.getInputJsonForPDF(formData); 
        const data: RenderDocTemplateRequest = {
          templateID: this.template.ID,
          dataToRender: dataToRender
        }
        const renderedTemplate = await this.$store.dispatch('api/renderDocTemplate', data).then((result) => {
          return result.data
        }).catch(err => {
          console.log(err, "error");
          this.$q.notify({ message: `Fout tijdens het verwijderen van template.`, color: 'negative'})
        });
        if(renderedTemplate) {
          this.$q.notify({ message: `Template renderen succesvol afgerond.`, color: 'positive'})
          this.renderedTemplate = renderedTemplate
          this.showDocDialog = true
        }
      } else {
        console.error('unsupported filetype: ', this.template?.fileType)
      }
    }

    onEditFormData() {
      this.showEditData = true;
    }

    get showEditDataMedia() {
      return true;
    }

    deleteMediaRecords() {
      const index = this.mediaData.indexOf(this.recordMediaToDelete);
      if (index > -1) {
        this.mediaData.splice(index, 1);
        this.recordMediaToDelete = {};
      }
    }

    @Watch('template')
    setTemplate() {
      if (this.template && this.template.id) {
        this.getOutputPDF(this.template);
      } else {
        this.src = '';
      }
    }

    @Watch('PORTAL')
    setIcons() {
      if (this.PORTAL.design) {
        this.designIcons = this.PORTAL.design.icons;
      }
    }

    async getModuleTemplates(): Promise<void> {
      if (!this.templates.length) {
        const pdfTemplates = await PdfTools.getModuleTemplates(this.module);
        console.log(this.loadedTemplates)
        const docTemplates = await this.getDocTemplates(this.module)
        this.loadedTemplates = [...pdfTemplates, ...docTemplates]
      }
    }

    async getDocTemplates(module: string): Promise<any> {
      const moduleTemplates = []
      const templates = await this.$store.dispatch('api/getDocTemplates').then((templates) => {
        if(templates && templates.data) return templates.data
        else return []
      }).catch(err => {
        console.log(err, "error");
      });
      for(const template of templates) {
        if(template.module === module) {
          template.name = '(DOCX) - ' + template.name
          moduleTemplates.push(template)
        }
      } 
      return moduleTemplates
    }

    async getOutputPDF(template, output = 'url'): Promise<void> {
      
      const generatePDF = functions.httpsCallable('GeneratePDF');
      const inputJSON = await this.getFormData();
      this.fileName = await this.getPDFname(template, inputJSON);

      const options = {
        tenant: this.curTenantID,
        request: 'mergeTemplate',
        templateID: template.id,
        fileName: this.fileName,
        format: 'pdf',
        output: output,
        inputJSON: inputJSON
      };

      

      await generatePDF(options).then((res: any) => {
        if (output === 'url') {
          this.src = res.data;
        }
        if (output === 'base64') {
          this.base64PDF = res.data;
        }
      })
      
    }

    async getPDFname(template, inputJSON) {
      let pdfData;
      if (this.pdfTemplatesData) {
        pdfData = this.pdfTemplatesData;
      } else {
        pdfData = await this.getPDFTemplatesData();
      }
      const fName = pdfData[String(template.id)] ? pdfData[String(template.id)].filename : template.name;
      return ListValues.render(fName, inputJSON);
    }

    async getFormData() {
      const formData = await PdfTools.getFormDataForPDF(this.formData.module, this.formData.data.ID);
      
      const inputJson = await PdfTools.getInputJsonForPDF(formData, this.mediaFieldName, (this.mediaData as any));
      
      return inputJson;
    }

    async mounted() {
      
      await this.getMediaData();
      await this.getModuleTemplates();
      if (this.defaultTemplate) {
        this.template = this.defaultTemplate;
      }

      const moduleSettings = await this.getModuleSettings(this.module);
      PdfTools.getModuleTemplates(this.module).then((res: any) => {
        const templates = res;
        
        if (moduleSettings.selectedPDFTemplateID) {
          const sevedTemplate = templates.find((t: any) => t.id === moduleSettings.selectedPDFTemplateID);
          if (sevedTemplate) {
            this.template = sevedTemplate;
          }
        }
      }).catch(() => {
        this.formTemplates = [];
      });
    }

    get initialized() {
      return store.state.initialization;
    }

    get curTenantID() {
      return this.tenantID;
    }

    get templates() {
      if (this.formTemplates.length) {
        return this.formTemplates;
      } else {
        return this.loadedTemplates;
      }
    }

  }
