
import {Component, Mixins, Watch, Prop} from 'vue-property-decorator';
import FormMixin, {FormData} from "@/components/Mixin/FormMixin";
import Toolbar from "@/components/Parts/Toolbar.vue";
import SideNavigationPanel from "@/components/Parts/SideNavigationPanel.vue";
import AbModel, {Fields} from "@/components/Models/AbModel";
import DynamicSection from "@/components/Forms/DynamicSection.vue";
import OrderInfo from "@/components/Base/Parts/OrderInfo.vue";
import PrintPreview from '@/components/Settings/Sections/Templates/PDFgenerator/Components/PrintPreview.vue';
import ListValues from "@/components/Mixin/ListValues";
import NewUserData from "@/dto/api/NewUserData.dto";
import CF from '../Helpers/CFunctions';
import StatusStepper from "@/components/Base/Parts/StatusStepper.vue";
import Utils from '@/utils/Utils';
import AuditLogs from "@/components/AuditLogs/Components/AuditLogs.vue"
import store from '@/store';

@Component({
  components: {PrintPreview, Toolbar, SideNavigationPanel, DynamicSection, OrderInfo,AuditLogs}
})

export default class DynamicForm extends Mixins(FormMixin) {
  @Prop() moduleListShown
  model: AbModel = new AbModel();
  dynamicModule = true;
  FormData = new class FormDataClass implements FormData {
    form = "dynamicForm";
    module = '';
    data = new Fields();
    callbackAfterSave = null;
    callbackAfterDelete = {};
    callbackAlert = false;
    callbackAfterSaveRead = null;
    dropDownValues = {};
    relatedFields = {};
    dynamicFields = {};
    dynamicModule = {};
    dynamicSections = {};
  };
  dynFields = {};
  dynSections = {};
  showPrintPreview = false;
  showTimeLine = false;
  previewKey = 1;
  showTemplateEmailForm = {value: false, pdfToSend: null};
  copyShowOrderInfo = false;
  tab = "";
  // user api
  async onToolbarAddUser() {
    const userData: NewUserData = {
      account: this.formData.data['account']['ID'],
      contact: this.formData.data['ID'],
      emailAddress: this.formData.data['email'],
      tenant: this.$store.state.tenantID,
      portalUser: true
    }
    await this.$store.dispatch('api/signUpNewPortalUser', userData).then(() => {
      this.$q.notify({ message: `Gebruiker aangemaakt.`, color: 'positive'})

    }).catch(err => {
      console.log(err, "error");
      this.$q.notify({ message: `Fout bij het aanmaken van een nieuwe gebruiker.`, color: 'negative'})
      
    });
  }

  get isShowAddUser() {

    return this.formData.module == 'contacts'; // and has portal
  }

  // RBAC
  get isModalSaveShow() {
    return !this.formData.locked && (!this.formData.guard || this.formData.guard.check('update') || !this.formData.data.ID);
  }

  onShowOrderInfoToggled(value) {
    this.copyShowOrderInfo = value;
  }
  
  onCloseTimeLine(value) {
    this.showTimeLine = value;
  }

  get statusStepper() {
    const result = Array<{}>();
    const statusField = (this.dynFields as any).status;
    const isStepperNeeded = statusField && statusField.type === 'status' && statusField.metadata;
    if (isStepperNeeded) {
      Object.keys(statusField.metadata).forEach(e => result.push(statusField.metadata[e]))
    }
    return result.sort((a, b) => (a as any).order - (b as any).order);
  }

  get showStatusStepper() {
    return this.statusStepper.length > 0
  }

  getStatusIcon(icon) {
    let result = '';
    if (!icon) {
      result = 'fas fa-tools'
    } else {
      if (icon.substr(-4) === '.svg') {
        result = 'img:' + icon
      } else {
        result = icon
      }
    }
    return result
  }

  get layoutStyle() {
    let style = '';
    if (this.isModalForm) {
      if (this.isDoubleModal) {
        style = 'height: calc(90vh - 0px); border-radius: 10px'
      } else {
        style = 'height: 95vh; border-radius: 10px'
      }
    } else {
      style = 'height: 100vh'
    }
    return style
  }

  get customButtons() {
    return CF.customButtons(this.dynModuleData);
  }

  doCustomButtonAction(buttonAction) {
    if(buttonAction.type !== "FieldUpdate" || buttonAction.type == "CreateRecord") {
      this.saveAndStayTheForm()
    }
    CF.doCustomButtonAction(buttonAction, this.formData.module, this.formData.data.ID);
  }

  onToolbarSave() {
    this.saveTheForm()
  }

  async saveOnToolbarBack() {
    console.log('save on toolbar back');
    if(!Utils.isEmpty(this.formData.data.ID)) {
      const dataHasChanged = await this.checkDataChanged()
      console.log('data has changed: ', dataHasChanged);
      if(dataHasChanged) {
        this.saveTheForm()
      }
    }
  }

  onToolbarUnlock() {
    this.formData.locked = false;
    this.formData.data.locked = false;
    this.formData.lockSave = true;
    this.setAllFieldsLocked(false);
    this.formData.noCloseModalForm = true;
    this.saveTheForm();
  }

  onToolbarLock() {
    this.formData.data.locked = true;
    this.formData.locked = true;
    this.formData.lockSave = true;
    this.formData.noCloseModalForm = true;
    const res = this.saveTheForm();
    
    if (res) {
      this.setAllFieldsLocked(true);
    } else {
      // not valid
      this.formData.data.locked = false;
      this.formData.locked = false;
      this.formData.lockSave = false;
    }
    
  }

  get showEmailBtn() {
    return Boolean(Object.values(this.dynSections).find((s: any) => s.type === 'emails'));
  }

  onToolbarEmailFormShow() {
    this.showTemplateEmailForm.value = true;
  }

  onToolbarPrintPreview(pdfToSend = null) {
    this.previewKey++;
    this.showPrintPreview = !this.showPrintPreview;
    if (!this.showPrintPreview && pdfToSend) {
      this.sendPDFAsEmail(pdfToSend);
    }
  }

  sendPDFAsEmail(pdfToSend) {
    
    this.showTemplateEmailForm.value = true;
    if (pdfToSend) {
      this.showTemplateEmailForm.pdfToSend = pdfToSend;
    }
  }

  get dynTitle() {
    const translationKey = `modules.${this.dynModuleData.id}.singularName`
    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
      return store.state.fieldTranslations[translationKey]
    }
    return this.dynModuleData ? this.dynModuleData.singularName || this.dynModuleData.name : this.dynModuleName;
  }

  formSectionsLocal() {
    return false;
  }

  get formSections() {
    if (this.formSectionsLocal) {
      const localSections = this.formSectionsLocal();
      if (localSections) {
        return localSections;
      }
    }
    const result: Array<any> = [];
    let indx = 0;
    for (const s in this.dynSections) {
      const sec = {
        id: s,
        name: s,
        ...this.dynSections[s],
        fields: {
          first: [] as any,
          second: [] as any,
          full: [] as any
        },
      };
      if (!sec.order) {
        sec.order = 0;
      }
      this.$set(sec, 'scrollVisible', false);
      const dFields: Array<any> = [];
      for (const f in this.dynFields) {
        const nf = {...{name: f}, ...this.dynFields[f]};
        if (!nf.order) {
          nf.order = 0;
        }
        dFields.push(nf);
      }
      sec.fields.first = dFields.filter(f => f.section === s && f.column === 1).sort((a, b) => {
        return a.order - b.order
      });
      sec.fields.second = dFields.filter(
        f => f.section === s && f.column === 2).sort((a, b) => {
        return a.order - b.order
      });
      sec.fields.full = dFields.filter(f => f.section === s && !f.column).sort((a, b) => {
        return a.order - b.order
      });
      this.$set(result, indx, sec);
      indx++;
    }
    result.sort((a, b) => {
      return a.order - b.order;
    });

    return result;
  }

  async focusToNextField(fieldName) {

    // call all query to load data for related fields before set focus
    for (const field in this.dynFieldsActionList) {
      const dynComp = this.dynFieldsActionList[field];
      if (dynComp.loadRelatedOptions) {
        await dynComp.loadRelatedOptions();
      }
    }

    (this.formSections as any).forEach((section, sectionIndex) => {

      ['first', 'second', 'full'].forEach(column => {
        section.fields[column].forEach((field, fieldIndex) => {
          if (field.name === fieldName) {
            this.formData.focusedFieldName = '';
            this.setFocusControl(sectionIndex, column, fieldIndex);
            return
          }
        });
      });
      if (this.formData.focusedFieldName) {
        return;
      }
    })
  }

  setFocusControl(sectionIndex, column, fieldIndex) {
    const section = this.formSections[sectionIndex];
    if (fieldIndex + 1 === section.fields[column].length) {
      if (column === 'first' && section.fields.second.length) this.setFocusToField(sectionIndex, 'second', 0);
      else if (column === 'second' && section.fields.full.length) this.setFocusToField(sectionIndex, 'full', 0);
      else this.setFocusToSection(sectionIndex + 1);
    } else {
      this.setFocusToField(sectionIndex, column, fieldIndex + 1)
    }
  }

  setFocusToField(sectionIndex, column, fieldIndex) {
    const field = this.formSections[sectionIndex].fields[column][fieldIndex];
    if (field.hidden || field.disabled || field.type === 'autoNumber' || field.type === 'calculatedField') {
      
      this.setFocusControl(sectionIndex, column, fieldIndex);
      return
    }
    this.formData.focusedFieldName = field.name;
    //this.$refs[section.name][0].$refs[field.name][0].$refs[field.name].$refs[field.name].focus();
  }

  setFocusToSection(sectionIndex) {
    if (sectionIndex === (this.formSections as any).length) {
      
      return
    }
    const section = this.formSections[sectionIndex];
    if (section.type !== 'fieldSection') {
      
      this.setFocusToSection(sectionIndex + 1);
      return
    }

    if (section.fields.first.length) this.setFocusToField(sectionIndex, 'first', 0);
    else if (section.fields.second.length) this.setFocusToField(sectionIndex, 'second', 0);
    else if (section.fields.full.length) this.setFocusToField(sectionIndex, 'full', 0);
    else this.setFocusToSection(sectionIndex + 1);
  }

  onBaseFieldChanged(field) {
    // check onChange
    if (field.onChange) {
      for (const fieldKey in field.onChange) {
        const changeTmp = field.onChange[fieldKey];
        if (changeTmp) {
          const value = ListValues.render(changeTmp, this.formData.data);
          this.formData.data[fieldKey] = value;
        }
      }
    }

    if (!this.formData.formChangedFields.includes(field.name) && !this.formData.dynReadOnlyFields[field.name]) {
      if (ListValues.bigTypingField(field.type)) {
        
        this.formData.formChangedFields.push(field.name)
      }
    }
  }

  setUnsavedData() {
    this.unsavedData++;
  }

  @Watch('formData.dynamicFields', {deep: true})
  handler() {
    this.dynFields = this.formData.dynamicFields;
    this.dynSections = this.formData.dynamicSections;
  }

  get isShowNavBar() {
    return !this.isModalForm;
  }

  // scroll sections
  onScroll({position}) {
    if (this.scrollingPage !== true) {
      this.updateActiveSection(position);
    }
  }

  updateActiveSection(position) {
    const sections: any = this.formSections;
    let last;
    for (const i in sections) {
      const section = sections[i];
      const item = this.$refs[section.id][0].$el;
      if (!item) {
        continue;
      }
      const { offsetHeight, offsetTop } = item;
      if(!last) {
        last = section.id;
      } else { 
        if (position > (offsetTop - 85) && position < (offsetTop - 85) + offsetHeight) {
          last = section.id;
        }
      }
    }

    if (last) {
      this.activeSection = last;
    }
  }
}
