import store from "../../store";
import { Timestamp } from '@firebase/firestore';
import {date, uid} from "quasar";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { State, Getter, Action } from "vuex-class";
import AbModel, { Fields } from "@/components/Models/AbModel";
import debounce from 'debounce';
import { EventBus } from "@/event-bus";
import ViewModel from "@/components/Views/ViewModel";
import ListValues from "@/components/Mixin/ListValues";
import RBAC from "@/components/Settings/Sections/UserManagement/RoleManagement/Models/RBAC";
import ViewSubFormModel from "@/components/Views/ViewSubFormModel";
import unlayerTools from "@/components/Settings/Sections/Templates/EmailTemplates/Models/UnlayerTools";
import {uuid} from "vue-uuid";
import mime from "mime-types";
import * as lodash from "lodash";
import cloneDeep from "lodash/cloneDeep"
import db from "@/db";
import Utils from "@/utils/Utils";

export interface FormData {
  form: string;
  module: string;
  data: Fields;
  callbackAfterSave: object | null | Promise<void>;
  callbackAfterDelete: object | null | Promise<void>;
  callbackAlert: boolean;
  callbackAfterSaveRead: object | null | Promise<void>;
}

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: { },
})
export default class FormMixin extends Vue {
  @Prop(String) ID;
  @Prop(Object||null) modalFormObjData;
  @Prop(String) action;
  @Prop(Function) callbackAfterSave;
  @Prop([String, Boolean]) isModalForm;
  @Prop([String, Boolean]) isModalDynamicForm;
  @Prop(Object) parentFormData;
  @Prop(String) subFormField;
  @Prop(Object) subFormFieldInfo;
  @Prop(String) dynModuleName;
  @Prop(Object) initParams;
  @Prop([String, Boolean]) isDoubleModal;

  @State("currentUser") currentUser;
  @State("moneyFormat") moneyFormat;
  @State("percentageFormat") percentageFormat;
  @State("dynamicModules") dynamicModules;
  @State("PORTAL") portalMode;
  @State("portalViewName") portalViewName;
  @State("portalViewHidden") portalViewHidden;
  @Action("settings/getModuleAlwaysShown") getModuleAlwaysShown;
  @Action("settings/getModuleOrderInfoShow") getModuleOrderInfoShow;
  @Action("settings/getModuleSettings") getModuleSettings;
  @Action("settings/setModuleSettings") setModuleSettings;

  @Getter("locale") locale;
  @Getter("language") language;
  @Getter("systemVariables") systemVariables;

  module = "";
  dynamicModule = false;
  model = new AbModel();
  savedID = "";

  formData = new class FormDataClass implements FormData {
    form = "";
    module = "";
    locked = false;
    lockSave = false;
    data = new Fields();
    callbackAfterSave = null;
    callbackAfterDelete = {};
    callbackAlert = false;
    callbackAfterSaveRead: any = null;
    dropDownValues = {};
    relatedFields = {};
    dynamicFields = {};
    alwaysShownFields = {};
    orderInfoShow = true;
    dynamicModule = {};
    dynamicSections = {};
    dynAutoNumbers = {};
    dynReadOnlyFields = {};
    unsubscribeData = null;
    noCloseModalForm = false;
    changedFields: Array<string> = [];
    formChangedFields: Array<string> = [];
    editingFields: Array<string> = [];
    formChangedFieldsByTypes = {};
    autoSave = false;
    bigTypeFieldEditing = false;
    notAutoSave = false;
    focusedFieldName = "";
    bufferedDataToSave = {};
    _form_uid = ''; // it's for check onDocumentDataChanged from this form or external
    guard: any = null;
    _saving = false; // form is saving
  };

  dropValuesLoaded = false; // all data read from DB
  formDataLoaded = false; // form data loaded
  drawer = null;
  valid = true;
  dropDownValues = {};
  oldData = null;

  activeSection = 0;
  sections = [{title: "", id: "", icon: "", num: 0}];
  scrollingPage = false;
  unsavedData = 0;
  moneyFields = {};
  hoursFields = {};
  percentageFields = {};
  modelDataLoaded = false;
  formMounted = false;
  unsavedWarning = 'Do you really want to leave? You have unsaved changes!';
  notNeedJsonStringifyFields: Array<string> = []; // fields for convertToObject without Json Stringify
  copyFormDataData = {};
  checkFirstRelatedCopyDone = false;
  validationList = {}; // all fields with validate() method
  resetValidationList = {}; // all fields with resetValidation() method
  dynFieldsActionList = {}; // all fields with all callback method
  checkNameTimer: any = null;
  typeTextTimer: any = 0;
  texDebounceTime = 2500; // text type debounce sec.
  lockedFieldsFirstSet = true;
  pdfTemplates = [];
  pdfDefaultTemplate = null;
  pdfTemplatesData = null;
  tenantEmailsTemplates = null;
  userEmailsTemplates = null;
  copyShowOrderInfo = false;

  async initTheForm(): Promise<void> {
    (this.module as any) = this.currentModule;
    this.formData.module = this.currentModule;

    this.modelDataLoaded = false;
    this.formMounted = false;
    const recordData = await this.initModel();
    await this.initModuleSettings();
    let data = {};
    if (this.currentID) {
      this.oldData = cloneDeep(recordData);
      (this.oldData as any) = await this.getModelData(this.oldData);
      this.oldData = this.fixFormData(this.oldData);
      (data as any) = await this.getModelData(recordData);
      data = this.fixFormData(data);
      if (data) {
        this.setNewModelFields(data);
        this.resetUnsavedData();
      } else {
        console.log('Record not found - ' + this.currentID + ' module - ' + this.currentModule);
        store.state.alertMessage = 'not_found';
        if (this.isModalForm) {
          this.$emit("receiveResetShowForm");
        } else {
          this.$router.go(-1)
        }
        return;
      }
    } else {
      data = this.getAllFields();
      this.setDefaultValues(data);
      this.copyToTheNewForm(data);
      this.localInitForm(data);
    }

    this.initRBAC();

    (this.formData.data as any) = data;
    const formUid = uid();
    this.formData._form_uid = formUid;
    this.formData.data._form_uid = formUid;
    this.copyFormDataData = { ... this.formData.data };
    this.formDataLoaded = true;

    // RBAC permission
    // read only for no update
    const fieldsLocked = this.formData.data.ID && !this.formData.guard.check('update');
    if (fieldsLocked) {
      this.setAllFieldsLocked(fieldsLocked);
    }

    // no read
    if (!this.formData.guard.check('read')) {
      store.state.alertMessage = 'no_permission';
      if (!this.isModalForm) {
        this.$router.go(-1);
      } else {
        this.$emit("refreshTheList");
        this.$emit("receiveResetShowForm");
      }
    }

    // add, no create
    if (!this.currentID && !this.formData.guard.check('create')) {
      store.state.alertMessage = 'no_permission';
      if (!this.isModalForm) {
        this.$router.go(-1);
      } else {
        this.$emit("refreshTheList");
        this.$emit("receiveResetShowForm");
      }
    }

    // portal permission
    if (store.state.PORTAL && this.currentID) {
      if (!this.checkAccountPermission()) {
        store.state.alertMessage = 'no_permission';
        if (!this.isModalForm) {
          this.$router.go(-1);
        } else {
          this.$emit("refreshTheList");
          this.$emit("receiveResetShowForm");
        }
      }
    }

  }

  initRBAC() {
    this.formData.guard = new RBAC(this.formData);
    
  }

  async getModelData(recordData = null) {
    await this.subscribeModelData();
    if (recordData) {
      return recordData;
    } else {
      return await this.model.getByID(this.currentID, this.currentModule);
    }
  }

  async subscribeModelData() {
    if (!this.formData.unsubscribeData) {
      this.formData.unsubscribeData = await this.model.subscribeDoc(this.currentID, this.currentModule, this.onDocumentDataChanged);
    }
  }

  onDocumentDataChanged(data) {
    if (!this.formDataLoaded || !data) {
      return;
    }

    const formUid = this.formData._form_uid;
    const dataUid = data._form_uid;

    if (formUid !== dataUid) {
      // changes from other form
      this.formData.formChangedFields = [];
    }

    // check locked
    const locked = data.locked;
    if (Boolean(locked) !== Boolean(this.formData.locked)) {
      this.formData.locked = locked;
      this.formData.data.locked = locked;
      this.setAllFieldsLocked(locked);
      this.resetUnsavedData();
      store.state.alertMessage = locked ? "locked" : 'unlocked';
    }

    // no permission to update
    const fieldsLocked = data.ID && !this.formData.guard.check('update');
    if (fieldsLocked) {
      this.setAllFieldsLocked(fieldsLocked);
    }

    // use only name of the sections
    const allFields = {...this.formData.dynamicFields, ...this.formData.dynamicSections};
    allFields['__error'] = {
      column: 1,
      defaultValue: false,
      hidden: true,
      label: "Error bij integratie",
      name: "__error",
      order: 1,
      section: "general",
      type: "checkbox",
      userDefined: false
    }
    allFields['__errorMessage'] = {
      column: 1,
      disabled: true,
      hidden: true,
      label: "Foutmelding van de integratie",
      mandatory: false,
      name: "__errorMessage",
      order: 2,
      section: "general",
      type: "text",
      userDefined: false
    }

    for(const afield in allFields) {
      const existData = this.formData.data[afield];
      const newData = data[afield];
      let diff = false;
      if (typeof newData !== 'undefined' && typeof existData !== 'undefined') {
        if (typeof newData !== 'object' && typeof existData !== 'object') {
          if (newData !== existData) {
            diff = true;
          }
        } else if (newData !== null && existData !== null) {
          // TODO:: check compare object conditions
          if (Object.prototype.hasOwnProperty.call(newData, 'ID')
            && Object.prototype.hasOwnProperty.call(existData, 'ID')
            && newData.ID !== existData.ID) {
            diff = true;
          }
          if (Object.prototype.hasOwnProperty.call(newData, 'name')
            && Object.prototype.hasOwnProperty.call(existData, 'name')
            && newData.name !== existData.name) {
            diff = true;
          }
          if (Object.prototype.hasOwnProperty.call(newData, 'seconds')
            && Object.prototype.hasOwnProperty.call(existData, 'seconds')
            && newData.seconds !== existData.seconds) {
            diff = true;
          }
          if (
            Array.isArray(newData) && Array.isArray(existData)
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore: Unreachable code error
            && !lodash.isEqual([...newData].sort(), [...existData].sort())
          ) {
            diff = true;
          }
        } else {
          if (newData !== existData) {
            diff = true;
          }
        }
      }

      if (diff) {
        if(this.formData['editingFields'] && this.formData['editingFields'].length) {
          for(const f in this.formData.editingFields) {
            const field = this.formData.editingFields[f]
            if(this.formData.dynamicFields && this.formData.dynamicFields[field] && this.formData.dynamicFields[field].type !== 'calculatedField') {
              data[field] = this.formData.data[field]
            }
            this.formData.editingFields.splice(Number(f), 1)
          }
        }
        if (!this.formData.formChangedFields.includes(afield)) {
          if(this.formData.dynReadOnlyFields[afield]) {
            this.formData.data[afield] = data[afield];
            const dataToUpdate = {name: afield, value: data[afield], module: this.formData.module}
            EventBus.$emit('needToUpdateFieldValueAfterCopy', dataToUpdate);
            continue
          } else {
            this.formData.data[afield] = data[afield];
            this.resetUnsavedData();
            this.formData.changedFields.push(afield)
            const dataToUpdate = {name: afield, value: data[afield], module: this.formData.module}
            EventBus.$emit('needToUpdateFieldValueAfterCopy', dataToUpdate);
          }
        }
      }
    }
    this.formData.formChangedFields = []
  }

  setNewModelFields(data) {
    if (this.model) {
      const fields = this.getAllFields();
      for (const field in fields) {
        if (!Object.prototype.hasOwnProperty.call(data, field)) {
          data[field] = fields[field];
        }
      }
      if(this.formData['editingFields'] && this.formData['editingFields'].length) {
        for(const f in this.formData.editingFields) {
          const field = this.formData.editingFields[f]
          data[field] = this.formData.data[field]
        }
      }        
    }
  }

  saveTheForm(needCallbackAlert = true, addSettings: any = {}) {
    if (!this.formData.data.ID && !this.formData.guard.check('create')) {
      return false;
    }
    if (this.formData.data.ID && !this.formData.guard.check('update')) {
      return false;
    }
    if (!this.checkPermission()) {
      return false;
    }
    if (this.dynamicModule) {
      this.model.setDynModuleName(this.currentModule);
    }

    if (!this.doValidate()) {
      // update the attachments only
      if (addSettings && addSettings.updateOnlyFields) {
        const dataToSave = this.formData.data;
        this.updateToModel(dataToSave, addSettings.updateOnlyFields);
        return true;
      } else {
        store.state.alertMessage = "not_valid";
        return false;
      }
    }
    this.formData.callbackAlert = true;
    if (!needCallbackAlert) {
      this.formData.callbackAlert = false;
    }
    const dataToSave = this.formData.data;
    for(const field in dataToSave) {
      if(store.state.dynamicModules[this.module].fields[field] && store.state.dynamicModules[this.module].fields[field].type === "dropdown") {
        const originalValue = this.findKeyByValue(this.module, field, dataToSave[field])
        dataToSave[field] = originalValue
      }
    }
    this.saveToModel(dataToSave);
    this.resetUnsavedData();
    return true;
  }


  findKeyByValue(moduleID: string, fieldName: string, targetValue: any): string | null {
    const translationKey = `${moduleID}.dropDowns.${fieldName}`
    for(const index in store.state.fieldTranslations) {
        if(store.state.fieldTranslations[index] === targetValue && index.includes(translationKey)) {
            const splittedKeyName = index.split('.')
            return splittedKeyName[splittedKeyName.length - 1]
        }
    }
    return targetValue;
  }

  saveTheFormFromSection(needCallbackAlert, addSettings) {
    this.formData.noCloseModalForm = true;
    this.saveTheForm(needCallbackAlert, addSettings);
  }

  saveAndCloseTheForm() {
    this.formData.autoSave = false;
    this.saveTheForm();
  }

  saveAndStayTheForm() {
    this.formData.noCloseModalForm = true;
    this.saveTheForm();
  }

  isNameAutoNumber() {
    return this.formData.dynAutoNumbers ? Object.prototype.hasOwnProperty.call(this.formData.dynAutoNumbers, 'name') : false;
  }

    updateToModel(dataToSave, fields) {
      const dataToUpdate = {};
      if (dataToSave.ID) {
        for (const field of [...['ID'], ...fields]) {
          dataToUpdate[field] = dataToSave[field];
        }
        this.model.update(dataToSave.ID, dataToUpdate);
        if (this.formData.callbackAlert) {
          store.state.alertMessage = "update";
        }
      }
    }

    saveToModel(dataToSave) {

    if (this.formData._saving) {
      return;
    }

    if (!dataToSave.ID) {
      this.formData._saving = true;
    }

    // eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    this.model.save(dataToSave, (ID) => {
      this.saveBufferedData(ID);
      if (this.formData.callbackAlert) {
        store.state.alertMessage = dataToSave.ID ? "update" : "add";
      }
      if (this.callbackAfterSave) {
        this.callbackAfterSave(ID);
      }
      if (this.formData.callbackAfterSave) {
        (this.formData as any).callbackAfterSave(ID);
      }
      if (this.isModalForm) {
        
        if (this.formData.noCloseModalForm) {
          // for save and stay
          this.savedID = ID;
          this.initTheForm();

          
          this.formData.noCloseModalForm = false
        } else {
          
          if (this.isModalDynamicForm) {
            
            if (!this.formData.autoSave) {
              
              this.$emit("refreshTheList");
              this.$router.go(-1);
            }
            this.formData.autoSave = false;
          } else {
            
            if (!this.formData.autoSave) {
              
              if (!this.checkNameTimer && this.isNameAutoNumber() && !this.formData.data.name) {
                
                this.savedID = ID;
                this.initTheForm();
                let timerLimit = 10;
                this.checkNameTimer = setInterval(() => {
                  
                  if (this.formData.data.name || !timerLimit--) {
                    
                    if (timerLimit < 1 && !this.formData.data.name) {
                      
                      store.state.alertMessage = "error";
                    }
                    clearInterval(this.checkNameTimer);
                    this.$emit("setAutoNumberName", this.formData.data);
                    this.$emit("receiveResetShowForm");
                  }
                }, 1000);
              } else {
                
                this.formData.autoSave = false;
                this.$emit("receiveResetShowForm");
              }
            }
          }
        }
      } else {
        if (this.afterSaveRead) {
          this.afterSaveRead(ID);
        }
      }
      if (!dataToSave.ID) {
        const tt = setInterval(() => {
          if (this.formData.data.ID) {
            clearInterval(tt);
            this.formData._saving = false;
            
          }
        }, 500);
      }
    }).catch((e) => {
      this.formData._saving = false;
      if (this.formData.callbackAlert) {
        console.log(e);
        store.state.alertMessage = "error";
      }
    });
  }

  saveBufferedData(ID) {
    for (const lineSection in this.formData.bufferedDataToSave) {
      const lineData = Object.values(this.formData.bufferedDataToSave[lineSection] || {});
      if (lineData.length) {
        const model = new AbModel();
        model.setDynModuleName(lineSection);
        lineData.forEach((d: any) => {
          const doc = {...d};
          doc.ID = '';
          doc.record = ID;
          model.save(doc);
        })
      }
    }
  }

  getAllFields() {
    const mFields = this.model.fields();
    const dFields = this.convertDynamicFields(this.model.dynFields(), this.model.dynSections());
    const result = { ...mFields, ...dFields };
    return result;
  }

  async initModuleSettings(): Promise<void> {
    // TODO cache it to the store for all modules by open it
    await this.getModuleSettings(this.module);
    let alwaysShown = await this.getModuleAlwaysShown(this.module);
    if (Object.keys(alwaysShown).length == 0) {
      alwaysShown = this.$store.state.settings.tenantDefaultAlwaysShown[this.module]
    }
    this.formData.alwaysShownFields = alwaysShown;
    this.formData.orderInfoShow = await this.getModuleOrderInfoShow(this.module);
    this.copyShowOrderInfo = this.formData.orderInfoShow;

    // email templates
    this.tenantEmailsTemplates = await unlayerTools.getTenantTemplates();
    this.userEmailsTemplates = await unlayerTools.getUserTemplates();
  }

  async saveModileSettings(data): Promise<void> {
    this.setModuleSettings({ module: this.module, data});
  }

  async initModel() {

    if (this.portalMode) {
      // TODO pay attention the extends form has own models, like as subform
      if (this.subFormField) {
        this.model = new ViewSubFormModel(this.portalViewName, this.currentModule);
      } else {
        this.model = new ViewModel(this.portalViewName, this.currentModule);
      }
    }

    if (this.dynamicModule) {
      this.model.setDynModuleName(this.currentModule);
    }
    if (this.subFormField) {
      this.model.setSubFormField(this.subFormField);
      this.model.setSubFormFieldData(this.subFormFieldInfo);
    }

    const recordData = await this.loadModelInfo();
    this.modelDataLoaded = true;
    this.resetUnsavedData();

    this.formData.relatedFields = this.relatedFields;

    this.formData.dynamicFields = this.model.dynFields();
    this.formData.dynamicModule = this.model.dynModule();
    this.formData.dynamicSections = this.model.dynSections();
    this.formData.dynAutoNumbers = this.model.dynAutoNumbers();
    this.formData.dynReadOnlyFields = this.model.dynReadOnlyFields();

    this.formData.locked = this.getIsFormLocked();
    this.setAllFieldsLocked(this.formData.locked);

    this.formData.dropDownValues = this.model.dropDownValues;
    this.dropDownValues = this.formData.dropDownValues;
    this.dropValuesLoaded = true;
    return recordData;
  }

  async loadModelInfo() {
    //setAllFieldsLocked
    const dbID = !this.modalFormObjData ? this.currentID : null;
    const data = await this.model.load(dbID);
    return data;
  }

  getIsFormLocked() {
    const lockedForm = this.model.getLocked();
    return lockedForm;
  }

  setAllFieldsLocked(locked) {
    const first = this.lockedFieldsFirstSet;
    const onlyRead = Boolean(locked);
    
    if (first) {
      for (const sec in this.formData.dynamicSections) {
        Vue.set(this.formData.dynamicSections[sec], 'locked', onlyRead);
      }
      for (const field in this.formData.dynamicFields) {
        Vue.set(this.formData.dynamicFields[field], 'locked', onlyRead);
      }
    } else {
      for (const sec in this.formData.dynamicSections) {
        this.formData.dynamicSections[sec].locked = onlyRead;
      }
      for (const field in this.formData.dynamicFields) {
        this.formData.dynamicFields[field].locked = onlyRead;
      }
    }
    this.lockedFieldsFirstSet = false;
  }

  getDefValueForType(ftype) {
    const types = {
      text: '',
      checkbox: false,
      number: null,
      date: null,
      datetime: null,
      dropdown: null,
      relatedField: {ID: '', name: ''},
      noType: null,
      subForm: [],
      relatedMultiSelect: [],
      attachment: [],
      media: [],
    };
    return types[ftype];
  }

  convertDynamicFields(fields, sections) {
    const result = {};

    for(const field in fields) {
      const ftype = fields[field].type || 'noType';
      const tVal = this.getDefValueForType(ftype);
      const value = tVal === false ? false
                  : tVal === '' ? ''
                  : tVal || null;

      result[field] = value;
    }
    // add sections fields
    for(const sec in sections) {
      if (sections[sec].type === 'attachments') {
        result[sec] = [];
      }
      if (sections[sec].type === 'media') {
        result[sec] = [];
      }
    }
    return result;
  }

  localInitForm(data) {
    if (this.initParams) {
      for (const param in this.initParams) {
        if (Object.prototype.hasOwnProperty.call(data, param) || param.includes('__')) {
          data[param] = this.initParams[param];
        }
      }
    }
    return data;
  }

  fixFormData(data) {
    let needToSave = false;
    const dvalue = this.getDefValueForType('relatedField');
    for(const fn in this.formData.dynamicFields) {
      const field = this.formData.dynamicFields[fn];

      // fix to must be ID
      if (field.type === 'relatedField' && data) {
        // console.log('data[fn]', fn, data[fn]);
        if (data[fn] && !Object.prototype.hasOwnProperty.call(data[fn], 'ID')) {
          data[fn] = dvalue;
        }
      }

      // fix relatedMultiSelect for empty values
      if (data && field.type === 'relatedMultiSelect') {
        if (!Array.isArray(data[fn])) {
          data[fn] = [];
        }
        data[fn] = data[fn].filter(d => d);
      }

      // set ID for subForm records if they are empty
      if (data && field.type === 'subForm') {
        if (!Array.isArray(data[fn])) {
          data[fn] = [];
        }
        data[fn] = data[fn].map(v => {
          if (!v.ID) {
            v.ID = uuid.v1();
            needToSave = true;
          }
          return v;
        });
      }
    }

    // fix file ext from the fileType
    for (const sn in this.formData.dynamicSections) {
      const sec = this.formData.dynamicSections[sn];
      if (data && (sec.type === 'attachments' || sec.type === 'media')) {
        data[sn] = (data[sn] || []).map(at => {

          
          if (at.fileType !== undefined && at.fileType !== null && at.fileType !== '') {
            let fileExt = ''
            if(at.fileName && typeof at.fileName === "string"){
              fileExt = at.fileName.split('.').pop();
            }

            let corExt;            
            if(fileExt.toLowerCase() !== 'jpg') {
              corExt = mime.extension(at.fileType);
            }
            
            if (at.fileType && corExt && corExt !== fileExt) {
              at.fileName += '.' + corExt;
              needToSave = true;
            }
            return at;
          }else{
          return at;
          }

        })
      }
    }
    if (needToSave) {
      this.model.save(data);
    }
    return data;
  }

  addFieldToValidationList(data) {
    this.validationList[data.name] = data.validate;
    this.resetValidationList[data.name] = data.resetValidation;
    this.dynFieldsActionList[data.name] = data;
  }

  doValidate() {
    let error = false;

    for(const field in this.resetValidationList) {
      this.resetValidationList[field]();
    }

    for(const field in this.validationList) {
      if (!this.validationList[field]()) {
        error = true;
      }
    }

    return !error;
  }

  async onDeleteForm() {
    this.resetUnsavedData();
    if (this.formData.data.ID) {
      this.model.delete(this.formData.data.ID, this.formData.module)
        .then(() => {
          store.state.alertMessage = "delete";
          if (!this.isModalForm) {
            this.$router.go(-1);
          } else {
            this.$emit("refreshTheList");
            this.$emit("receiveResetShowForm");
          }
        });
    }
  }

  setDefaultValues(data) {
    for (const fieldName in (this.formData.dynamicFields || {})) {
      const dynField = this.formData.dynamicFields[fieldName];
      if (Object.prototype.hasOwnProperty.call(dynField, 'defaultValue')) {
        const defValue = dynField.defaultValue;
        
        if (ListValues.isDateType(dynField)) {
          // set date & time default values
          data[fieldName] = ListValues.getDateTimeDefaultValue(defValue);
        } else if (typeof defValue === 'string' && defValue[0] === '$') {
          data[fieldName] = this.systemVariables[defValue];
        } else {
          data[fieldName] = defValue;
        }
      }
    }
    if (this.setPortalLocalDefaultFields) {
      this.setPortalLocalDefaultFields();
    }
  }

  setPortalLocalDefaultFields() {
    return null;
  }

  copyToTheNewForm(data) {
    
    if (this.parentFormData && this.parentFormData.copyToNewForm) {
      for (const field in this.parentFormData.copyToNewForm) {
          const copyFields = this.parentFormData.copyToNewForm[field];
          for(const indx in copyFields) {
            const copyField = copyFields[indx];
           // 
            let pVal: any = '';
            if (field === '$originRecord') {
              pVal = {
                ID: this.parentFormData.data.ID || '',
                name: this.parentFormData.data.name || '',
              }
            } else {
              const sfvals = field.split('.');
              pVal = this.parentFormData.data[sfvals[0]];
              if (sfvals[1]) {
                pVal = this.parentFormData.data[sfvals[0]][sfvals[1]];
              }
            }
          //  
            if (pVal) {
              data[copyField] = pVal;
            }
          }
      }
    }
  }

  afterSaveRead (id) {
    this.savedID = id;
    if (!this.ID) {
      const uri = window.location.href;
      history.replaceState({}, '', uri.replace('/add', '/edit/' + this.savedID));
    }
    if (!this.formData.autoSave && !this.formData.lockSave) {
      
      this.initTheForm();
    }
    this.formData.autoSave = false
    this.formData.lockSave = false;
  }

  // by Back
  onBackForm(isModalForm = false) {
    if (!isModalForm || this.isModalDynamicForm) {
      this.$emit("refreshTheList");
      this.$router.go(-1);
    } else {
      this.$emit("receiveResetShowForm");
    }
  }

  // by Cancel
  onCloseForm(isModalForm = false) {
    if (!isModalForm || this.isModalDynamicForm) {
      this.$emit("refreshTheList");
      this.$router.go(-1);
    } else {
      this.$emit("receiveResetShowForm");
    }
  }

  emitRelated(records): void {
    records.forEach(r => {
      this.sections.push({
        title: r.label,
        id: r.module,
        icon: "fas fa-arrows-alt-h stroke-transparent",
        num: this.sections.length,
      })
    })
  }

  beforeMount() {
    this.loadNewForm();
  }

  loadNewForm() {
    if (!this.checkPermission()) {
      this.$router.go(-1);
    }
    if (!this.currentID && !this.checkPermission()) {
      this.$router.go(-1);
    }
    this.initTheForm();
  }

  checkPermission() {
    return true;
  }

  checkAccountPermission() {
    return true;
  }

  get initialized() {
    return this.dropValuesLoaded && this.formDataLoaded && this.showFormSettingLoaded;
  }

  get showFormSettingLoaded() {
    return true;
  }

  get hiddenParts() {
    if (this.portalMode) {
      // 
      return this.portalViewHidden;
    } else {
      return {};
    }
  }

  cValue(i) {
    if (i && i.name) {
      return i.name;
    }
    if (i && i instanceof Date) {
      return date.formatDate(i, "YYYY-MM-DD HH:mm");
    }
    if (i && typeof i.toDate === "function") {
      return date.formatDate(i.toDate(), "YYYY-MM-DD HH:mm");
    }
    return i;
  }

  convertToObject(data) {
    const saveTs = {};
    const saveFields = {};

    this.notNeedJsonStringifyFields.map((f) => {
      if (data[f]) {
        saveFields[f] = data[f];
      }
    });

    for (const f in data) {
      if (data[f] && typeof data[f].toDate === "function") {
        saveTs[f] = data[f];
      }
    }

    data = JSON.parse(JSON.stringify(data));

    for (const f in saveTs) {
      data[f] = saveTs[f];
    }

    for (const f in saveFields) {
      data[f] = saveFields[f];
    }

    for (const f in data) {
      if (data[f] && Object.prototype.hasOwnProperty.call(data[f], 'seconds') && Object.prototype.hasOwnProperty.call(data[f], 'nanoseconds')) {
        data[f] = this.fromObjToTimestamp(data[f]);
      } else {
        if (Array.isArray(data[f])) {
          data[f].map(d => {
            for(const v in d) {
              if (d[v] && Object.prototype.hasOwnProperty.call(d[v], 'seconds') && Object.prototype.hasOwnProperty.call(d[v], 'nanoseconds')) {
                d[v] = this.fromObjToTimestamp(d[v]);
              }
            }
          });
        }
      }
    }

    return data;
  }

  fromObjToTimestamp(o) {
    const sec = o.seconds
    return Timestamp.fromMillis(sec * 1000);
  }

  get relatedFields() {
    return store.state.dynamic;
  }

  checkBeforeUnload(evt) {
    if (this.unsavedData) {
      evt.returnValue = this.unsavedWarning;
      return this.unsavedWarning;
    }
  }


  created () {
    window.addEventListener('beforeunload', this.checkBeforeUnload)
  }

  removeFieldsThatDoNotNeedToBeSaved(fieldConfig: any, data: any) {
    if(!data) return null
    const keys = Object.keys(data);
    for(const key in keys) {
      const field = keys[key];
      if (fieldConfig[field] && fieldConfig[field].type == 'calculatedField') {
        delete data[field]
      } else if (fieldConfig[field] && fieldConfig[field].type == 'autoNumber') {
        delete data[field]
      } else if (fieldConfig[field] && fieldConfig[field].name && fieldConfig[field].name.startsWith("__")) {
        delete data[field]
      } else if (fieldConfig[field] && fieldConfig[field].name && fieldConfig[field].name === "_form_uid") {
        delete data[field]
      } else if(fieldConfig[field] && fieldConfig[field].readOnly) {
        delete data[field]
      }
    }
    return data
  }

  async beforeDestroy() {
    if(this.formData.data.ID) {
      const oldData = this.removeFieldsThatDoNotNeedToBeSaved(store.state.dynamicModules[this.module].fields, this.oldData)
      const formData = this.removeFieldsThatDoNotNeedToBeSaved(store.state.dynamicModules[this.module].fields, this.formData.data)
      if(!lodash.isEqual(oldData, formData)) {
        const dataHasChanged = await this.checkDataChanged()
        if(dataHasChanged) {
          this.saveTheForm()
        }
      }
    }
    window.removeEventListener('beforeunload', this.checkBeforeUnload)
    clearInterval(this.checkNameTimer);
    clearTimeout(this.typeTextTimer);
    if (this.isModalForm && !this.isDoubleModal) {
      this.onCloseForm(this.isModalForm);
    }
    if (typeof this.formData.unsubscribeData === "function") {
      (this.formData.unsubscribeData as any)();
    }
    this.resetUnsavedData();
  }

  async checkDataChanged() {
    let oldData = await this.getRecord(this.formData.module, this.formData.data.ID)
    let diff = false
    oldData = this.removeFieldsThatDoNotNeedToBeSaved(store.state.dynamicModules[this.module].fields, oldData)
    const formData = this.removeFieldsThatDoNotNeedToBeSaved(store.state.dynamicModules[this.module].fields, this.formData.data)
    const changedFields = []
    for(const field in formData) {
      if(!lodash.isEqual(formData[field], oldData[field])) {
        changedFields.push(field);
        diff = true;
      }
    }
    if(changedFields.length === 1 && changedFields[0] === "_form_uid") return false
    if (diff) return true
    return false
  }


  async getRecord(moduleID: string, recordID: string) {
    if(!moduleID || !recordID) return null
    const record = await db.doc(`tenants/${store.state.tenantID}/modules/${moduleID}/records/${recordID}`).get()
    if(record.exists) {
      return record.data()
    }
    return null
  }

  beforeRouteLeave (to, from , next) {
    if (this.unsavedData && !this.formData.data.ID) {
      const answer = window.confirm(this.unsavedWarning);
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else next()
  }

  @Watch('formData.data', {deep: true})
  handlerData = debounce(this.checkUnsavedData, 100);

  @Watch('formData.data', {deep: true})
  handlerCopy = debounce(this.checkFirstRelatedCopy, 1000);

  @Watch('formData.data', {deep: true})
  handlerEmitData(){
    this.$emit('emitChangedData', this.formData.data);
  }

  @Watch('unsavedData', {deep: true})
  handlerSave = debounce(this.autoSaveStart, 1000);

  @Watch('ID', {deep: true})
  handlerIdData(){
    console.log('ID', this.ID);
    //this.loadNewForm();
  }


  autoSaveStart() {
    // debounce the text, number fields for more time
    if (this.formData.bigTypeFieldEditing) {
      clearTimeout(this.typeTextTimer);
      this.typeTextTimer = setTimeout(this.setUnsavedDataByTimer, this.texDebounceTime);
    } else {
      this.autoSave();
    }
  }

  setUnsavedDataByTimer() {
    
    this.autoSave();
  }

  autoSave() {
    if (this.formData.locked) {
      return false;
    }
    if (this.unsavedData && this.formData.data.ID && !this.formData.notAutoSave) {
      if (!this.checkPermission()) {
        this.unsavedData = 0;
      }
      this.formData.notAutoSave = false;
      
      this.formData.autoSave = true;
      this.saveTheForm()
    }
  }

  checkUnsavedData() {
    let diff = false;
    this.formData.bigTypeFieldEditing = false;
    for(const field in this.formData.data) {
      
      if (this.formData.data[field] !== this.copyFormDataData[field]) {
        if (!(!this.formData.data[field] && !this.copyFormDataData[field]) ||
          typeof this.formData.data[field] !== typeof this.copyFormDataData[field]) {
          
          if (!this.formData.dynReadOnlyFields[field]) {
            const dField = this.formData.dynamicFields[field];
            if (dField && ListValues.bigTypingField(dField.type)) {
              this.formData.bigTypeFieldEditing = true;
            }
            diff = true;
            if (dField && dField.type) {
              const value = this.formData.formChangedFieldsByTypes[dField.type];
              this.$set(this.formData.formChangedFieldsByTypes, dField.type, value ? (value + 1) : 1);
              // 
            }
          }
          if (this.formData.data.ID) {
            this.formData.changedFields.push(field);
          }
        }
      }
    }
    if (diff) {
      if (this.initialized && this.modelDataLoaded) {
        this.unsavedData++;
      } else {
        this.unsavedData = 0;
      }
      this.copyFormDataData = { ... this.formData.data };
    }
  }

  checkFirstRelatedCopy() {
    // first related copy after form created
    if (!this.formData.data.ID && this.initialized && this.modelDataLoaded && !this.checkFirstRelatedCopyDone) {
      EventBus.$emit('needRelatedCopyForNewForm', { module : this.subFormField || this.currentModule });
      this.checkFirstRelatedCopyDone = true;
    }
  }

  resetUnsavedData() {
    this.unsavedData = 0;
    this.copyFormDataData = { ... this.formData.data };
    this.formData.formChangedFieldsByTypes = {};
  }

  get currentID() {
    return this.ID || this.savedID;
  }

  get currentModule() {
    return this.dynModuleName || this.formData.module;
  }

  get dynModuleData() {
    // TODO from formData
    return this.dynamicModules && this.dynamicModules[this.dynModuleName] ? this.dynamicModules[this.dynModuleName] : {} ;
  }

}
