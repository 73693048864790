
import { Component, Prop, Vue } from "vue-property-decorator";
import FieldChange from "../../Models/FieldChange";

@Component({
    components: {}
})
export default class AuditLogRelatedField extends Vue {
    @Prop({required: true})change: FieldChange;
    @Prop()onlyNewValue: boolean;
    loading = true

    get checkIfTheSame(){
        if(this.oldValue === this.newValue){
            return true
        }
        return false
    }

    get oldValue(){
        if(this.change.oldValue){
            const name = JSON.parse(this.change.oldValue).name
            if(name){
                return name
            }
        }
        return this.$t("auditLogs.messages.emptyValue")
    }
    get newValue(){
        if(this.change.newValue){
            const name = JSON.parse(this.change.newValue).name
            if(name){
                return name
            }
        }
        return this.$t("auditLogs.messages.emptyValue")
    }

    mounted() {
        this.loading = false;
    }
}
