
import { Component, Prop, Vue } from "vue-property-decorator";
import { date } from 'quasar'
import FieldChange from "../../Models/FieldChange";

@Component({
    components: {}
})
export default class AuditLogDateAndDateTimeField extends Vue {
    @Prop({required: true})change: FieldChange;
    @Prop()onlyNewValue: boolean;
    loading = true


    getHumanDate (value: string) { 
        if(this.change.fieldType === 'datetime'){
            return date.formatDate(value, 'DD-MM-YYYY HH:mm')
        }
        return date.formatDate(value, 'DD-MM-YYYY')

    }

    get oldValue(){
        if(this.change.oldValue){
            return this.getHumanDate(this.change.oldValue)
        }
        return this.$t("auditLogs.messages.emptyValue")
    }
    get newValue(){
        if(this.change.newValue){
            return this.getHumanDate(this.change.newValue)
        }
        return this.$t("auditLogs.messages.emptyValue")
    }

    mounted() {
        this.loading = false;
    }
}
