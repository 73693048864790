
  import {date} from "quasar";
  import store from "./../../store";
  import { mapState } from "vuex";
  import jexl from 'jexl';
  import draggable from 'vuedraggable';
  import BaseFieldRecordImage from "../Base/BaseFieldRecordImage.vue";
  import StatusStepper from "@/components/Base/Parts/StatusStepper.vue";
  import Dialog from "@/components/Parts/Dialog.vue";
  import sortList from '@/common/helpers/utilities';
  import db from '@/db';
  import { doc, setDoc, getDoc } from "firebase/firestore";
  import cloneDeep from "lodash/cloneDeep"

  export default {
    name: "Toolbar",
    components: {
      StatusStepper,
      BaseFieldRecordImage,
      Dialog,
      draggable
    },
    props: {
      info: {},
      module: {},
      singularName: String,
      pluralName: String,
      unsavedData: null,
      showOrderInfo: null,
      dynTitle: null,
      customButtons: null,
      isModalForm: null,
      isDoubleModal: null,
      locked: null,
      // eslint-disable-next-line vue/require-prop-type-constructor
      isShowActions: true,
      formData: null,
      isSaving: null,
      isShowAddUser: null,
      isShowEmailBtn: null
    },
    data() {
      return {
        openInfoModal: false,
        buttonDisabled: false,
        deleteFormsModal: false,
        timeRecordDeleteModal: false,
        timeRecords: [],
        purchaseRecords: [],
        showRawRecordData: false,
        debugMode: false,
        maximizedToggle: true,
        firstCustomButton: null,
        recordData: null,
        customButtonsList: [],
        dbCustomButtons: {}
      };
    },
    async created() {
      const customButtons = await this.getCustomButtons(this.module)
      this.dbCustomButtons = cloneDeep(customButtons)
      for(const customButton in customButtons) {
        this.customButtonsList.push(customButtons[customButton])
      }
      this.customButtonsList = sortList(this.customButtonsList, 'order')
      this.firstCustomButton = this.customButtonsList[0]
    },
    computed: {
      ...mapState(['PORTAL', "portalViewHidden"]),
      isSubMenuShow() {
        if (this.PORTAL) {
          return !this.portalViewHidden.hideSubMenu;
        } else {
          return this.isShowActions;
        }
      },
      // RBAC
      isAddShow() {
        return !this.locked && this.formData.guard.check('create');
      },
      isDeleteShow() {
        return !this.locked && this.formData.guard.check('delete');
      },
      isLockShow() {
        return !this.locked && this.formData.guard.check('lock');
      },
      isUnLockShow() {
        return this.locked && this.formData.guard.check('unlock');
      },
      isSaveShow() {
        return !this.locked && this.formData.guard.check('update');
      },
      showRecordImage() {
        return store.state.dynamicModules[this.module].recordImage ? store.state.dynamicModules[this.module].recordImage : false
      },
      isStatusOverwriteShow() {
        if(this.formData.guard.check('statusOverwrite') && this.formData && this.formData.dynamicFields && this.formData.dynamicFields.status){
          return this.formData.dynamicFields.status.disabled
        } 
        return false
      }
    },
    methods: {
      async getCustomButtons(moduleID) {
        const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/customButtonsOrder/${moduleID}`);
        const record = await getDoc(ref);
        if(record.exists()) {
          const recordData = record.data()
          for(const customButton in recordData.customButtons) {
            if(!this.customButtons[customButton]) continue
            this.customButtons[customButton].order = recordData.customButtons[customButton]
          }
          return this.customButtons
        }
        return this.customButtons
      },
      setDebugModeTrue() {
        this.debugMode = !this.debugMode
        store.state.debugMode = this.debugMode
      },
      buttonLocked(cbutton) {
        let emptyField = false
        if(cbutton && cbutton.disableExpression) {
          try {
            emptyField = jexl.evalSync(cbutton.disableExpression, this.formData.data)
          } catch  {
            return true
          }
        }
        return this.locked && !cbutton.executableWhenLocked || this.isSaving || emptyField
      },
      onBack() {
        setTimeout(() => {
          this.$emit("onToolbarBack");
        }, 350);
      },
      onDelete() {
        this.$emit("onToolbarDelete");
      },
      openDeleteModal() {
        this.timeRecords = []
        this.purchaseRecords = []
        if(this.info.timeRecord) {
          this.timeRecords.push(this.info)
        }
        if(this.info.purchaseRecord) {
          this.purchaseRecords.push(this.info)
        }
        if(this.timeRecords.length || this.purchaseRecords.length) {
          this.timeRecordDeleteModal = true
        }
        if(!this.timeRecordDeleteModal) {
          this.deleteFormsModal = true
        }
      },
      onSave() {
        this.$emit("onToolbarSave");
      },
      onPrintPreview() {
        this.$emit("onToolbarPrintPreview");
      },
      toggleInfoModal() {
        this.openInfoModal = !this.openInfoModal;
      },
      onCustomButton(customButton) {
        if(this.buttonLocked(customButton)) {
          return
        }
        //TODO what's the use of this for loop ?
        this.buttonDisabled = true
        for(const action of customButton.actions) {
          this.$emit('onCustomButton', customButton)
        }
        setTimeout(() => {
          this.buttonDisabled = false
        }, 5000);
      },
      onUnlock() {
        this.$emit('onToolbarUnlock');
      },
      onAddUser() {
        this.$emit('onToolbarAddUser');
      },
      onLock() {
        this.$emit('onToolbarLock');
      },
      onToolbarEmailFormShow() {
        this.$emit('onToolbarEmailFormShow')
      },
      onStatusOverwrite() {
        this.formData.dynamicFields.status.disabled = false
      },
      toDateTimeValue(val) {
        if (val && typeof val.toDate === "function") {
          return date.formatDate(val.toDate(), store.state.dateTimeMask);
        }
        if (val && val instanceof Date) {
          return date.formatDate(val, store.state.dateTimeMask);
        }
        return val;
      },
      async getRecordByID(recordID) {
        const ref = doc(db, `tenants/${store.state.tenantID}/modules/${this.module}/records/${recordID}`);
        return (await getDoc(ref)).data();
      },
      async onShowRawRecordData() {
        this.recordData = await this.getRecordByID(this.info.ID)
        this.showRawRecordData = true
      },
      async saveCustomButtonOrder() {
        const customButtons = {}
        for(const i in this.customButtonsList) {
          this.customButtonsList[i].order = i
          customButtons[this.customButtonsList[i].name] = i
        }
        const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/customButtonsOrder/${this.module}`);
        await setDoc(ref, {customButtons: customButtons});
        return
      }
    },
    watch: {
      'customButtonsList': {
        handler() {
          let orderChanged = false
          this.firstCustomButton = this.customButtonsList[0]
          for(const index in this.customButtonsList) {
            const customButton = this.customButtonsList[index]
            customButton.order = index
            if(customButton.order !== this.dbCustomButtons[customButton.name].order) {
              orderChanged = true
            }
          }
          if(orderChanged) this.saveCustomButtonOrder()
        }
      }
    }
  };
