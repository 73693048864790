
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuditLogFieldType } from "../../Models/AuditLogFieldType";
import ParsedAuditLog from "../../Models/ParsedAuditLog";
import AuditLogCheckboxField from "../Fields/AuditLogCheckboxField.vue";
import AuditLogCurrencyField from "../Fields/AuditLogCurrencyField.vue";
import AuditLogDateAndDateTimeField from "../Fields/AuditLogDateAndDateTimeField.vue";
import AuditLogDecimalField from "../Fields/AuditLogDecimalField.vue";
import AuditLogNumberField from "../Fields/AuditLogNumberField.vue";
import AuditLogPercentageField from "../Fields/AuditLogPercentageField.vue";
import AuditLogTextField from "../Fields/AuditLogTextField.vue";

@Component({
    components: {
        AuditLogTextField,
        AuditLogPercentageField,
        AuditLogCurrencyField,
        AuditLogCheckboxField,
        AuditLogDateAndDateTimeField,
        AuditLogNumberField,
        AuditLogDecimalField
    }
})
export default class AuditLogRelatedFieldCopy extends Vue {
    @Prop({required: true})auditLog: ParsedAuditLog;
    loading = true
    AuditLogFieldType = AuditLogFieldType

    mounted() {
        this.loading = false;
    }
}
