
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { Action } from "vuex-class";
import { date } from 'quasar'
import AuditLog from "../Models/AuditLog";
import ParsedAuditLog from "../Models/ParsedAuditLog";
import { Utils } from "handlebars";
import { AuditLogType } from "../Models/AuditLogType";
import { AuditLogOperation } from "../Models/AuditLogOperation";
import store from "@/store";
import AuditLogUserFieldUpdates from "./AuditlogTypes/AuditLogUserFieldUpdates.vue"
import AuditLogCalculatedFields from "./AuditlogTypes/AuditLogCalculatedFields.vue";
import AuditLogAutoNumberFields from "./AuditlogTypes/AuditLogAutoNumberFields.vue";
import AuditLogRelatedFieldCopy from "./AuditlogTypes/AuditLogRelatedFieldCopy.vue";
import AuditLogAutoFieldUpdates from "./AuditlogTypes/AuditLogAutoFieldUpdates.vue";
import AuditLogAggregatedFields from "./AuditlogTypes/AuditLogAggregatedFields.vue";
import AuditLogRecordCreated from "./AuditlogTypes/AuditLogRecordCreated.vue"
import AuditLogAccountSpecificFinancialFields from "./AuditlogTypes/AuditLogAccountSpecificFinancialFields.vue";
import AuditLogBefore2023 from "./AuditlogTypes/AuditLogBefore2023.vue";
import { AuditLogFieldType } from "../Models/AuditLogFieldType";


@Component({
    components: {
        AuditLogUserFieldUpdates,
        AuditLogCalculatedFields,
        AuditLogAutoNumberFields,
        AuditLogRelatedFieldCopy,
        AuditLogAutoFieldUpdates,
        AuditLogAggregatedFields,
        AuditLogRecordCreated,
        AuditLogAccountSpecificFinancialFields,
        AuditLogBefore2023
    }
})
export default class AuditLogs extends Vue {
    @Prop(String) module;
    @Prop(String) recordID;
    @Action("api/getAuditLogForRecord") getAuditLogForRecord;
    maximizedToggle = true;
    logsData: ParsedAuditLog[] = [];
    loading = false;
    auditLogIcons = {
        'created': 'add',
        'updated': 'edit',
        'relatedFieldCopy': 'edit',
        'calculatedField': 'calculate',
        'fieldUpdate': 'edit',
        'autoNumber': 'tag',
        'aggregatedField': 'calculate',
        'accountSpecificFinancialFields': 'euro',
        'auditLogBefore2023': 'event'
    };
    AuditLogType = AuditLogType

    getFieldType(field: string): string{
        let fieldType = ''
        if( store.state.dynamicModules[this.module].fields[field] && store.state.dynamicModules[this.module].fields[field].type){
            fieldType = store.state.dynamicModules[this.module].fields[field].type
            if(fieldType === AuditLogType.CALCULATEDFIELD){
                return store.state.dynamicModules[this.module].fields[field].fieldType
            }
        }
        return fieldType
    }

    extractAction(log: AuditLog): string | void {
        if (log.field === '__auditLogInfo'){
            switch (JSON.parse(log.newValue).type) {
                case AuditLogType.FIELDUPDATE:
                case AuditLogType.RELATEDFIELDCOPY:
                    return JSON.parse(log.newValue).ID
                default:
                    break;
            }
        }
    }

    extractType(log: AuditLog): AuditLogType | void {
        if(log.operation === AuditLogOperation.CREATED){
            return AuditLogType.CREATED
        } else if (log.field === '__auditLogInfo'){
            const type = JSON.parse(log.newValue).type
            switch (type) {
                case AuditLogType.CALCULATEDFIELD:
                case AuditLogType.FIELDUPDATE:
                case AuditLogType.RELATEDFIELDCOPY:
                case AuditLogType.AGGREGATEDFIELD:
                    return type
                default:
                    break;
            }
        } else if (log.field === '__autoNumbersSet'){
            return AuditLogType.AUTONUMBER
        } else if (log.field === '__productFieldsFetched'){
            return AuditLogType.ACCOUNTSPECIFICFINANCIALFIELDS
        }
    }

    logsBefore2023(logs: ParsedAuditLog[]): boolean{
        if(logs){
            const firstLogDate = new Date(logs[logs.length - 1]?.changedAt)
            const logsStartDate = new Date("01 Jan 2023 00:00:00 GMT")
            if(firstLogDate <= logsStartDate){
                return true
            }
        }
        return false
    }

    filterAuditlog(field: string): boolean{
        switch (field) {
            case 'changedAt':
            case 'changedBy':
            case '__calculatedFieldsSet':
            case '__autoNumbersSet':
            case '_form_uid':
                return true
            default:
                return false
        }

    }

    parseLogs(logs: AuditLog[]): ParsedAuditLog[]{
        const auditLogs = {}
        for (const log of logs) {
            if(!(log.changedAt in auditLogs)){
                auditLogs[log.changedAt] = {changedAt: log.changedAt, changedBy: log.changedBy, changes: []} as ParsedAuditLog
            }
            if(Utils.isEmpty(auditLogs[log.changedAt].type)){
                auditLogs[log.changedAt].type = this.extractType(log)
            }
            if(Utils.isEmpty(auditLogs[log.changedAt].action)){
                auditLogs[log.changedAt].action = this.extractAction(log)
            }
            const fieldType = this.getFieldType(log.field)
            let fieldLabel = log.field
            if(store.state.dynamicModules[this.module].fields[log.field] && store.state.dynamicModules[this.module].fields[log.field].label){
                fieldLabel = store.state.dynamicModules[this.module].fields[log.field].label
            }
            if(!this.filterAuditlog(log.field)){
                auditLogs[log.changedAt].changes.push({oldValue: log.oldValue, newValue: log.newValue, field: log.field, fieldType: fieldType, fieldLabel: fieldLabel}) 
            }
        }
        const parsedAuditLogs: ParsedAuditLog[] = []
        const parsedAuditLogsUnfiltered: ParsedAuditLog[] = Object.values(auditLogs)
        for (const parsedAuditLog of parsedAuditLogsUnfiltered) {
            if(Utils.isEmpty(parsedAuditLog.type)){
                parsedAuditLog.type = AuditLogType.UPDATED
            }
            for(const change of parsedAuditLog.changes){
                if(Object.values(AuditLogFieldType).includes(change.fieldType as any)){
                    parsedAuditLogs.push(parsedAuditLog)
                    break
                }
            }
        }
        if(this.logsBefore2023(parsedAuditLogs)){
            const date = "2023-01-01T00:00:00.000Z"
            const parsedAuditLog = new ParsedAuditLog(date, {"ID": "system", "name":"system"}, [])
            parsedAuditLog.type = AuditLogType.AUDITLOGBEFORE2023
            let index = "0"
            for(const auditLogIndex in parsedAuditLogs) {
                const auditLog = parsedAuditLogs[auditLogIndex]
                if(auditLog.changedAt < date) {
                    index = auditLogIndex
                    break
                }
            }
            parsedAuditLogs.splice(Number(index), 0 , parsedAuditLog)
        }
        return parsedAuditLogs
    }

    async getLogs() {
        const params = {
            module: this.module,
            record: this.recordID
        };
        const rawLogs: AuditLog[] = await this.getAuditLogForRecord(params)
        this.logsData = this.parseLogs(rawLogs);
        return
    }

    getHumanDate (value) {
        return date.formatDate(value, 'DD MMMM YYYY, HH:mm')
    }

    getTitleForItemType(value: AuditLogType) {
        switch (value) {
            case AuditLogType.CREATED:
                return this.$t("auditLogs.messages.recordIsCreated");
            case AuditLogType.UPDATED:
                return this.$t("auditLogs.messages.userUpdatedRecord");
            case AuditLogType.RELATEDFIELDCOPY:
                return this.$t("auditLogs.messages.relatedFieldUpdate");
            case AuditLogType.CALCULATEDFIELD:
                return this.$t("auditLogs.messages.calculatedField");
            case AuditLogType.FIELDUPDATE:
                return this.$t("auditLogs.messages.fieldUpdate");
            case AuditLogType.AUTONUMBER:
                return this.$t("auditLogs.messages.autoNumber");   
            case AuditLogType.AGGREGATEDFIELD:
                return this.$t("auditLogs.messages.calculatedField");
            case AuditLogType.ACCOUNTSPECIFICFINANCIALFIELDS:
                return this.$t("auditLogs.messages.accountSpecificFinancialFields");      
        }
    }

    @Emit('closeTimeLine')
    closeDialog() {
        return false
    }

    async mounted() {
        this.loading = true;
        await this.getLogs();
        this.loading = false;
    }
}
