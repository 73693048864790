import { render, staticRenderFns } from "./PrintPreview.vue?vue&type=template&id=17cfb760&"
import script from "./PrintPreview.vue?vue&type=script&lang=ts&"
export * from "./PrintPreview.vue?vue&type=script&lang=ts&"
import style0 from "./PrintPreview.vue?vue&type=style&index=0&id=17cfb760&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QLayout,QDrawer,QList,QItem,QItemSection,QIcon,QItemLabel,QSelect,QBtn,QPageContainer,QCard,QToolbar,QTable,QTr,QTd,QImg,QCardActions,QDialog,QCardSection,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QDrawer,QList,QItem,QItemSection,QIcon,QItemLabel,QSelect,QBtn,QPageContainer,QCard,QToolbar,QTable,QTr,QTd,QImg,QCardActions,QDialog,QCardSection})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
