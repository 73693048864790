
import { Prop, Vue, Component, Emit } from "vue-property-decorator";
import store from "@/store"

@Component({
  components: {},
})


export default class FilePickerDialog extends Vue {
    @Prop() formData;
    @Prop() module;
    @Prop() showDialog;
    images = []
    dialog = false

    mounted() {
        this.dialog = this.showDialog
    }

    async setImage(file) {
        file.storageRef = `modules/${this.module}/records/${this.formData.ID}/recordImage/`;
        file.toPublic = true;
        file.onlyRef = true;
        file.addTenant = true;
        file.toPublic = false
        const uploadedFile = await store.dispatch('uploadFileToStorage', file);
        this.emitImage(uploadedFile)
        return uploadedFile
    }
    async setCompanyLogo(recordImages) {
        const uploadedFile = await this.setImage(recordImages[0]);
        return uploadedFile.downloadableURL
    }
    onFileRejected () {
        this.$q.notify({type: 'negative', message: 'File did not pass validation constraints'})
    }

    @Emit('closeFilePickerDialog')
    closeDialog() {
        this.dialog = false
        return false
    }

    @Emit('uploadedFile')
    emitImage(file) {
        return file
    }
}
