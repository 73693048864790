import { AuditLogType } from "./AuditLogType";
import FieldChange from "./FieldChange";
import RelatedField from "./RelatedField";

export default class ParsedAuditLog {
    changedAt: string;
    changedBy: RelatedField;
    changes: Array<FieldChange>;
    action: string;
    type!: AuditLogType;
    constructor(
        changedAt: string,
        changedBy: RelatedField,
        changes: Array<FieldChange>
    ) {
        this.changedAt = changedAt;
        this.changedBy = changedBy;
        this.changes = changes;
    }

}
