
import { Component, Prop, Vue } from "vue-property-decorator";
import FieldChange from "../../Models/FieldChange";

@Component({
    components: {}
})
export default class AuditLogCheckboxField extends Vue {
    @Prop({required: true})change: FieldChange;
    @Prop()onlyNewValue: boolean;
    loading = true

    get oldValue(){
        if(this.change.oldValue === null || this.change.oldValue === 'null'){
            return this.$t("auditLogs.messages.emptyValue")
        } else if(this.change.oldValue === false || this.change.oldValue === 'false'){
            return this.$t("auditLogs.messages.notTrue")
        }else {
            return this.$t("auditLogs.messages.true")
        }
    }
    get newValue(){
        if(this.change.newValue === null || this.change.newValue === 'null'){
            return this.$t("auditLogs.messages.emptyValue")
        } else if(this.change.newValue === false || this.change.newValue === 'false'){
            return this.$t("auditLogs.messages.notTrue")
        }else {
            return this.$t("auditLogs.messages.true")
        }
    }

    mounted() {
        this.loading = false;
    }
}
