
import store from "@/store";
import { mapActions } from "vuex"
import ListValues from "@/components/Mixin/ListValues";
import sortList from "@/common/helpers/utilities"
import ModuleInfoCard from "../../Modules/Components/ModuleInfoCard.vue"

export default {
  name: "OrderInfo",
  components: {ModuleInfoCard},
  props: {
    alwaysShownFields: {},
    orderInfoShow: null,
    moduleListShown: null,
    formDataData: {},
    formData: {},
    dynFields: {},
    module: null,
    dynTitle: null,
    isModalForm: null,
  },
  data(){
    return {
      orderInfoEditMode: false,
      orderedOrderInfo: [],
      showOrderInfo: this.orderInfoShow,
      orderInfoFields: []
    }

  },
  mounted(){
    this.getOrderInfo()
    this.getSelectOptions()
  },
  methods: {
    ...mapActions('settings', ['setModuleAlwaysShown', 'setModuleOrderInfoShow']),

    getOrderInfo() {
      const fieldsObj = this.alwaysShownFields || this.getDefaultOrderInfo();
      const orderInfoArray = Object.keys(fieldsObj).map(e => fieldsObj[e]);
      const result = orderInfoArray.sort((a, b) => a.order - b.order);

      this.orderedOrderInfo = result.map(e => {
        delete e.order
        return e
      });
    },
    getDefaultOrderInfo(){
      return {
        name: {
          field: 'name',
          label: this.dynFields['name'] ? this.dynFields['name'].label || 'name' : 'name',
          order: 1,
        },
        status: {
          field: 'status',
          label: this.dynFields['status'] ? this.dynFields['status'].label || 'status' : 'status',
          order: 2,
        }
      }
    },
    toggleOrderInfo(){
      this.showOrderInfo = !this.showOrderInfo;
      this.$emit('toggleShowOrderInfo', this.showOrderInfo);
      this.saveShowInfoSettings()
    },
    getOrderInfoLabel(field){
      return field.label || this.dynFields[field.field]?.label || '--'
    },
    getOrderInfoData(field){
      const value =  this.formDataData[field?.field] || null;
      return ListValues.transformOneByModule(value, this.module, field.field);
    },
    async getSelectOptions() {
      const fields = Object.values(this.dynFields);
      const activeFields = []
      for (const field of fields) { 
        if (!field.hidden || field.hidden == false) { 
          activeFields.push({field: field.name, label: field.label})
        }
      }
      this.orderInfoFields = await sortList(activeFields, 'label');
    },
    deleteFieldFromOrderInfo(index){
      this.orderedOrderInfo = this.orderedOrderInfo.filter((e, ind) => index !== ind)
    },
    getSavedData(items) {
      const savedData = {}
      items.forEach((e, index) => {
        savedData[e.field] = JSON.parse(JSON.stringify(e))
        savedData[e.field].order = index + 1;
      })

      return savedData
    },
    closeEditMode() {
      this.getOrderInfo();
      this.orderInfoEditMode = false;
    },
    saveInfoSettings() {
      this.getSavedData(this.orderedOrderInfo)
      const params = {
        module: this.module,
        data: {'always-shown-fields' : this.getSavedData(this.orderedOrderInfo)}
      };
      this.setModuleAlwaysShown(params).then(() => {
        store.state.alertMessage = "update";
        this.orderInfoEditMode = false
      })
    },
    saveShowInfoSettings() {
      const params = {
        module: this.module,
        data: {'order-info-show' : this.showOrderInfo}
      };
      this.setModuleOrderInfoShow(params).then(() => {
        
      })
    }
  }
}
