import { render, staticRenderFns } from "./Toolbar.vue?vue&type=template&id=75b37851&"
import script from "./Toolbar.vue?vue&type=script&lang=js&"
export * from "./Toolbar.vue?vue&type=script&lang=js&"
import style0 from "./Toolbar.vue?vue&type=style&index=0&id=75b37851&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QToolbar,QBtn,QIcon,QToolbarTitle,QTooltip,QSpinnerGears,QBtnDropdown,QItem,QItemSection,QAvatar,QItemLabel,QMenu,QList,QDialog,QCard,QLayout,QHeader,QBar,QSpace,QCardSection,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QToolbar,QBtn,QIcon,QToolbarTitle,QTooltip,QSpinnerGears,QBtnDropdown,QItem,QItemSection,QAvatar,QItemLabel,QMenu,QList,QDialog,QCard,QLayout,QHeader,QBar,QSpace,QCardSection,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
