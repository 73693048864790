
  import { scroll } from "quasar";
  import Utils from "@/utils/Utils"
  import store from "@/store"
  const { getScrollTarget, setScrollPosition } = scroll;
  export default {
    name: "SideNavigationPanel",
    props: {
      sections: Array,
      dynSections: Array,
      parentRefs: Object,
      activeSection: null,
      moduleName: String,
      moduleInfoHeight: {
          type: String,
          default: '90',
      },
      formID: null,
      formData: null,
      showStatusStepper: null
    },
    data() {
      return {
        openInfoModal: false,
        drawer: true,
      };
    },
    methods: {
      isHidden(sec) {
        const isHidden =  sec.hidden || !this.isModuleShow(sec);
        if(isHidden) return true;
        const fieldWiseControl = this.fieldWiseControl('HIDDEN', sec)
        if(fieldWiseControl) return true;
        return false      
      },
      fieldWiseControl(prop, sec) {
        if(this.formData.dynamicModule.sections[sec.name] && this.formData.dynamicModule.sections[sec.name].fieldWiseControl) {
          const fieldWiseControl = this.formData.dynamicModule.sections[sec.name].fieldWiseControl
          const field = fieldWiseControl.field
          const value = this.formData.data[field] 
          if(!Utils.isEmpty(value)  && fieldWiseControl && fieldWiseControl.values) {
            if(fieldWiseControl.values[value] === prop) {
              return true
            }
          }
        }
        return false
      },
      isModuleShow(sec) {
        if (sec.type === 'relatedRecords') {
          return this.formData.guard.checkModule(sec.relatedModule);
        }
        if (sec.type === 'productLines') {
          return this.formData.guard.checkModule(sec.lineType);
        }
        return true;
      },
      toggleInfoModal() {
        this.openInfoModal = !this.openInfoModal;
      },
      scrollToElement(el) {
        const element = this.filteredDynSections ? this.parentRefs[el][0].$el : this.parentRefs[el];
        const target = getScrollTarget(element);
        const offset = this.showStatusStepper ? element.offsetTop : element.offsetTop - 80; // -15 because of prettier alignment of the card
        const duration = 150;
        setScrollPosition(target, offset, duration);
      },
      sectionLabel(section) {

        let secName = section.label ?? section.name;
        if (this.moduleName && ! secName) {
          secName = this.$t(this.moduleName + ".headers." + (section.header ? section.header : section.id));
        }
        let translatedSectionName = secName
        if (store.state && store.state.fieldTranslations[`${this.formData.module}.sections.${section.name}`]) {
          translatedSectionName = store.state.fieldTranslations[`${this.formData.module}.sections.${section.name}`]
        }
        return translatedSectionName
        // return secName + ' ('+ section.order + ')';
      }
    },
    computed: {
      filteredDynSections(){
        const sections = this.formID ? this.dynSections
          : this.dynSections.filter(e => e.type === 'fieldSection' || e.type === 'subForm');
        return sections.filter(section => !this.isHidden(section));
      },
      isDebugMode() {
        return store.state.debugMode
      }
    }
  };
